import React, { Component } from 'react'
import PropTypes from 'prop-types'
import back from '../back'

class ProjectDetail extends Component{
    render() {
        const projects = this.props.project
        return (
            <div className="content">
                
                {back} 
                
                <div className="Pheader">{projects.title}</div>

                <div className="uneven">
                    <div className="internItem">{projects.projectInfoLeft}</div>

                    <div className="internFoto">
                        <img src={projects.imageSrc} alt="Foto" className="circle" />
                    </div>

                    <div className="internItem">{projects.projectInfoRight}</div>
                </div>
            </div> 
        );
    }
}

ProjectDetail.propTypes = {
    project: PropTypes.object.isRequired
}

export default ProjectDetail