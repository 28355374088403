import React, { Component } from 'react'
import tesla from '../../../assets/img/coders.png';
import layout from '../back'

export default class CodersAcademy extends Component {
    constructor() {
        super()
        this.state = {
            compententie: ""
        }
    }

    render() {
        return (
            <div className="content">
                {layout}
                <div className="Pheader">Coders Academy</div>
                <div className="uneven">
                    <div className="internItem">
                        <h3>Mijn stage en taken</h3>
                        
                        <p>
                            Tijdens mijn 3e jaars stage liep ik bij Coders Academy. Dit is een bedrijf wat werkt met stagiairs en de focus legt op het Agile werken.
                            Toen ik begon werd ik aan een aantal projecten toegevoegd en moest ik elke week een planning maken waar ik wanneer aan ging werken. 
                            Hierdoor kreeg je een duidelijk overzicht wat je wanneer moest gaan doen.
                        </p>

                        <p>
                            Na een tijd kreeg iedereen zijn eigen project en moest je dat als scrum-master begeleiden. Door deze werkwijze leerde
                            iedereen werken in een project en om er een te lijden. 
                        </p>
                    </div>
                    <div className="internFoto">
                        <img src={tesla} alt="foto" className="circle" />
                    </div>

                    <div className="internItem">
                        <h3>Wat heb ik geleerd?</h3>
                        <p>
                            Tijdens mijn stage heb ik veel geleerd over het samenwerken met anderen en te werken met een Agile werkmethode.
                            
                        </p>

                        <h4>Gebruikte talen/frameworks</h4>
                        <ul>
                            <li>WordPress</li>
                            <li>HTML & css</li>
                            <li>PHP</li>
                        </ul>
                    </div>
                </div>
            </div>
        );

    }
}