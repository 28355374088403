import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import advies from '../../../../../assets/img/proof/feedback/advies.png'
import { AdviesRapport } from './proof/AdviesRapport';

export default class Analyse1 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie Analyseren 1</h2>

                <h3>Info over de competentie</h3>
                <p>
                    Je kunt een analyse van een opdracht
                    uitvoeren gebaseerd op een praktische
                    onderzoeksvraag en maakt hierbij gebruik van
                    bestaande methoden en technieken.
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Een adviesrapport met de onderzoeksvraag.
                    'Hoe kunnen we routes genereren voor
                    schepen om over te kunnen navigeren' met
                    daarin gemaakte beslissingen en waarom de
                    beslissingen gemaakt zijn op basis van de
                    analyse van het probleem.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: advies,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Het begin van het project was veel tools analyseren zodat we een advies konden gaan geven.
                    Dit onderzoek verliep goed en heb de tools getest en gebruikt of geprobeerd te gebruiken.
                    Na het onderzoek op de verschillende tools hebben we een goed advies kunnen geven en hebben
                    we dankzij deze tool een mooie applicatie kunnen bouwen.
                </p>

                <h2>Bewijs</h2>
                {AdviesRapport}
            </div>
        );
    }
}