import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

import technology from '../../../../../../assets/img/proof/analyse/technologystack.png'

const fotoWidth = '25em'

export const TechnologyStack =
    <div>
        <h3>Technology stack</h3>
        <p>
            De RiverScout applicatie bestaat uit twee projecten. Het frontend project en het backend project.
            In dit document zal er een overzicht gemaakt worden wat voor technologieën deze gebruiken.
        </p>


        <p>
            In de onderstaande foto zie je met welke technologieën het project gemaat is.
            In de backend gebruiken we Kotlin als programmeertaal en gebruiken we het Springboot framework om er een restful API van te maken.
            Hierdoor kunnen we onze informatie die opgeslagen is in de MongoDB database open zetten naar buiten.
        </p>

        <p>
            In de frontend wordt er gebruik gemaakt van het ReactJS framework wat met JavaScript geprogrammeerd kan worden.
            Om de door backend open gestelde data te weergeven gebruikt de applicatie <a href="https://leafletjs.com/" style={{color: 'blue'}} target="blanc">Leaflet</a> waarin makkelijk een kaart geprint kan worden op het scherm.
            Met <a href="https://leafletjs.com/" style={{color: 'blue'}} target="blanc">Leaflet</a> map kunnen we verschillende dingen inladen zoals markers en lijnen die de routes in onze database kunnen weergeven.
        </p>
        <ImageZoom
            image={{
                src: technology,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />

        <p>
            In beide projecten hebben we gekozen om talen te gebruiken die binnen Teqplay ook gebruikt worden.
        </p>

        <p>
            In de backend hadden we de keuze tussen Java en Kotlin. Kotlin is het geworden zodat we het dit konden gaan leren en het een modernere taal is.
            (verdere info over deze keuze vind je in het advies rapport in Analyse 1 of Advieseren 1)
        </p>

        <p>
            De frontend applicatie was mijn eerste frontend project. Hiervoor heb ik samen met Babette en Bas (frontenders van Teqplay) gezeten om hier advies over 
            te vragen. Bij Teqplay gebruiken ze ReactJS. In ReactJS heb je de keuze om het in TypeScript of JavaScript te programmeren.
            Ik kreeg als advies om het in JavaScript te gaan doen omdat het project niet al te groot zou worden en dat het 
            voor een kleiner project niet nodig is om het in TypeScript te doen. 
            Om een kaart te gebruiken in ReactJS kreeg ik advies om hiervoor <a href="https://leafletjs.com/" style={{color: 'blue'}} target="blanc">Leaflet</a> te 
            gebruiken dit gebruiken ze in de projecten met een map. <a href="https://leafletjs.com/" style={{color: 'blue'}} target="blanc">Leaflet</a> is 
            een library voor ReactJS om zo eenvoudig een map te gebruiken en hier lijnen van routes op te printen.
        </p>
    </div>