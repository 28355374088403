import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { riverScout } from './proof/RiverScoutFrondend'

import ontwikkeling from '../../../../../assets/img/proof/feedback/ontwikkeling.png'
import codestandart from '../../../../../assets/img/proof/feedback/codestandart.png'

export default class Realiseren1 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie realiseren 1</h2>

                <h3>Info over de competentie</h3>
                <p>
                    Je kunt software realiseren conform de
                    requirements van de opdracht en met
                    kwaliteitsstandaarden zoals ze gebruikt
                    worden in software engineering of zoals ze
                    gehanteerd worden binnen het bedrijf.
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Verzorgde code geschreven volgens ontwerp
                    en alle requirements verwerkt in het project.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: ontwikkeling,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <ImageZoom
                    image={{
                        src: codestandart,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    De codestandarden naleven was af en toe nog even wennen. Maar al snel weet je je hoe je hier mee om moet gaan.
                    In de frontend veranderen de code standaarden veel maar ik wordt wel bij de meetings waar nieuwe technologieën en standaarden worden besproken uitgenodigd.
                    Dit helpt mij om het te verwerken en te oefenen in mijn frontend project.
                    Dit heeft mij goed geholpen te leren overzichtelijk en netjes te programmeren.
                </p>

                <h2 id="bewijs">Bewijs</h2>
                {riverScout}
            </div>
        );
    }
}