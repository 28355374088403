import React from 'react';
import './item.css';
import back from './img/back.png';

const Back = (
    <div>
        {/*eslint-disable-next-line*/}
        <a href="/">
            <div className="backButton">
                <img src={back}  className="img" alt="foto" />
            </div>
        </a>       
    </div>
)

export default Back