import React from 'react'

export const bedrijfsoriëntatie =
    <div>
        <h4>Oorsprong</h4>
        <p>
            Teqplay is een IT bedrijf, gevestigd in de Van Nellefabriek in Rotterdam. Teqplay is opgezet in
            2014 door de twee founders Leon Gommans en Richard van Klaveren. Het idee om een
            platform te maken met alle data betreffende, info over alles rondom en in de havens. Dit idee is
            begonnen op een hackathon en is na de hackathon tot een bedrijf gevormd, omdat hun idee
            zoveel mogelijkheden had.
        </p>

        <h4>Werkzaamheden</h4>
        <p>
            Bij Teqplay wordt veel gebruik gemaakt van data over schepen en alles rondom de haven. Door
            de verzamelde data goed te combineren met elkaar, kunnen de gemaakte applicaties gebruik
            maken van data uit het verleden en zo de toekomst te voorspellen voor hun klanten. De klanten
            zijn bedrijven in en rond de havens en Teqplay wil er voor zorgen dat de processen bij die
            bedrijven worden verbeterd met technologieën van nu.
        </p>


        <h4>Visie & missie</h4>
        <p>
            De missie van Teqplay is om bedrijven een inzicht te geven over alles wat zich in de havens
            afspeelt. Op dit moment heeft Teqplay veel klanten in Rotterdam, maar er zijn natuurlijk nog
            veel meer havens die zulke technologieën kunnen gebruiken. Het toekomstbeeld van Teqplay is
            om de huidige applicaties uit te werken tot een wereldwijd concept. Voor nu houd Teqplay het
            nog even bij Rotterdam en wil met de bedrijven die zich in de haven begeven naar het
            toekomstbeeld werken.
        </p>


        <h4>Bedrijfsstructuur</h4>
        <p>
            Bij Teqplay werken nu 12 man, waaronder 2 founders, 1 Data scientist, 1 Senior software
            engineer en 8 developers. Léon Gommans is één van de co-founders en is de Chief Executive
            Officer. Bij deze rol komen taken, betreffende de missie en visie van het bedrijf, communicatie
            met de partners. Richard van Klaveren is een co-founder en Chief Technical Officer. Zijn taken
            betreffen de technische visie en de architectuur van de software development.
            Verder heb dan nog een Senior software engineer, Deze rol wordt vervuld door Jos. De Senior
            software engineer doet hetzelfde werk als de developers maar hij heeft meer ervaring en heeft
            ook meer verantwoordelijkheden. De data scientist is Stephan. Een data scientist houd zich
            bezig met het verwerken van grote hoeveelheden data (big data).
            De developers van het bedrijf zijn Erik, Babette, Joris, Bas, Joost, Michel, Shravan, Damon en na deze stage Maurice en ik.
        </p>


        <h4>Sfeer</h4>
        <p>
            Binnen Teqplay is de sfeer informeel. Er wordt gewerkt in één ruimte en iedereen helpt elkaar,
            door de dagelijkse stand-up gaat dit ook makkelijker.
        </p>


        <h4>Werkmethode</h4>
        <p>
            Bij Teqplay wordt gewerkt in sprints van twee weken en aan het begin van deze sprint wordt
            een doel opgesteld wat we aan het einde van de sprint moeten hebben behaald. Aan het einde
            van de sprint wordt een demo gegeven aan de founders en stagebegeleiders. Zij zullen de
            stagiairs tijdens of na de demo feedback geven op de sprint en richtlijnen voor hoe het verder
            zal gaan.
        </p>


        <h4>Stagebegeleiding</h4>
        <p>
            Tijdens mijn stage word ik vooral ondersteund door twee developers, Joris en Babette. Zij zullen
            mij ondersteunen als ik vragen heb en zullen mij ook code-reviews geven hierdoor krijg ik snel
            feedback op mijn geschreven code.
            Mijn plek in het stagebedrijf. Ik zit in dezelfde ruimte als alle werknemers en zit aan tafel met de
            andere 3 stagiairs, Steven, Maurice en Catalina. Tijdens mijn stage werk ik aan een project, die
            is is afgesteld op wat ik wil leren. Voor het project functioneert Richard als de Product owner en
            worden wij ondersteund door Babette en/of Joris.
        </p>

    </div>