import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { Testplan } from './proof/Testplan';

import tests from '../../../../../assets/img/proof/feedback/tests.png'

export default class Analyse4 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie Analyseren 4</h2>
                <h3>Info over de competentie</h3>
                <p>
                    Je kunt een acceptatietest opstellen aan
                    de hand van kwaliteitseigenschappen die
                    gelden bij het bedrijf
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Een acceptatietest opstellen door middel van
                    een testplan opstellen die de
                    kwaliteitseigenschappen* checkt.
                </p>
                <p>
                    * Kwaliteitseigenschappen als in code
                    standaarden en code, functionaliteit,
                    onderhoudbaarheid, gebruiksvriendelijkheid,
                    efficiëntie, exploiteerbaarheid
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: tests,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Het is erg belangrijke om een applicatie te testen. Hiervoor is het testplan wat ik heb opgesteld.
                    Dankzij dit testplan heb ik een overzicht wat er getest wordt en hoe er getest wordt.
                    De applicaties die gebouwd zijn zowel frontend en backend worden veel getest.
                    Deze tests zijn bijna allemaal geautomatiseerd.
                </p>

                <p>
                    Ik ben erg tevreden hoe de applicaties nu getest worden. De tests worden nog steeds aangepast en verbeterd waardoor
                    de applicaties steeds beter getest worden.
                </p>

                <h2 id="bewijs">Bewijs</h2>
                {Testplan}

                <hr />

                <p>
                    Het test plan wordt uitgewerkt in de <a href="/internships/teqplay/realiseren2#bewijs" className="link">realiseren 2</a> competentie.
                </p>
            </div>
        );
    }
}
