import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import layout from '../../back'
import Dagboek from './docs/teqplay/Dagboek.pdf'
import Realiseren1 from './competenties/Realiseren1'
import Realiseren2 from './competenties/Realiseren2'
import Ontwerpen from './competenties/Ontwerpen'
import Skills1 from './competenties/Skills1'
import Skills23 from './competenties/Skills23'
import '../../item.css'
import Analyse1 from './competenties/Analyse1';
import Analyse2 from './competenties/Analyse2';
import Analyse3 from './competenties/Analyse3';
import Analyse4 from './competenties/Analyse4';
import Advies from './competenties/Advies';
import Beheer from './competenties/Beheer';
import Leerdoel1 from './competenties/Leerdoel1';
import Leerdoel2 from './competenties/Leerdoel2';
import Leerdoel3 from './competenties/Leerdoel3';
import TeqplayInfo from './TeqplayInfo';
import Stageplan from './Stageplan';
import notFound from '../../../notFound'

export default class Teqplay extends Component {
    
    render() {
        return (
            <div className="content">
                {layout}
                <div className="Pheader itern-header"><a href="/internships/teqplay" >Teqplay</a></div>
                <div className="uneven">
                    <BrowserRouter>
                        <Switch>
                            <Route path="/internships/teqplay" component={TeqplayInfo} exact />
                            <Route path="/internships/teqplay/stageplan" component={Stageplan} />

                            <Route path="/internships/teqplay/leerdoel1" component={Leerdoel1} />
                            <Route path="/internships/teqplay/leerdoel2" component={Leerdoel2} />
                            <Route path="/internships/teqplay/leerdoel3" component={Leerdoel3} />

                            <Route path="/internships/teqplay/beheer" component={Beheer} />

                            <Route path="/internships/teqplay/analyse1" component={Analyse1} />
                            <Route path="/internships/teqplay/analyse2" component={Analyse2} />
                            <Route path="/internships/teqplay/analyse3" component={Analyse3} />
                            <Route path="/internships/teqplay/analyse4" component={Analyse4} />

                            <Route path="/internships/teqplay/advies" component={Advies} />

                            <Route path="/internships/teqplay/ontwerpen" component={Ontwerpen} />

                            <Route path="/internships/teqplay/realiseren1" component={Realiseren1} />
                            <Route path="/internships/teqplay/realiseren2" component={Realiseren2} />

                            <Route path="/internships/teqplay/skills1" component={Skills1} />
                            <Route path="/internships/teqplay/skills2-3" component={Skills23} />

                            <Route component={notFound} exact/>
                        </Switch>
                    </BrowserRouter>

                    <div className="internItem">
                        <h2>Documenten</h2>

                        <ul>
                            <h3>Stageplan</h3>
                            <a href="/internships/teqplay/stageplan">
                                <li className={"liLink"}>
                                    Stageplan
                                </li>
                            </a>
                        </ul>

                        <ul>
                            <h3>Leerdoelen</h3>
                            <a href="/internships/teqplay/leerdoel1">
                                <li className={"liLink"} onClick={() => this.setState({ compententieText: "leerdoel1" })}>
                                    Leerdoel 1
                                </li>
                            </a>

                            <a href="/internships/teqplay/leerdoel2">
                                <li className={"liLink"}>
                                    Leerdoel 2
                                </li>
                            </a>

                            <a href="/internships/teqplay/leerdoel3">
                                <li className={"liLink"}>
                                    Leerdoel 3
                                </li>
                            </a>
                        </ul>

                        <ul>
                            <h3>Competenties</h3>

                            <h4>Beheren</h4>
                            <a href="/internships/teqplay/beheer">
                                <li className={"liLink"}>
                                    Beheren 1
                                </li>
                            </a>

                            <h4>Analyseren</h4>
                            <a href="/internships/teqplay/analyse1">
                                <li className={"liLink"}>
                                    Analyse 1
                                </li>
                            </a>

                            <a href="/internships/teqplay/analyse2">
                                <li className={"liLink"}>
                                    Analyse 2
                                </li>
                            </a>

                            <a href="/internships/teqplay/analyse3">
                                <li className={"liLink"}>
                                    Analyse 3
                                </li>
                            </a>

                            <a href="/internships/teqplay/analyse4">
                                <li className={"liLink"}>
                                    Analyse 4
                                </li>
                            </a>

                            <h4>Adviseren</h4>
                            <a href="/internships/teqplay/advies">
                                <li className={"liLink"}>
                                    Advieseren 1
                                </li>
                            </a>

                            <h4>Ontwerpen:</h4>
                            <a href="/internships/teqplay/ontwerpen">
                                <li className={"liLink"}>
                                    Ontwerpen 1 & 2
                                </li>
                            </a>

                            <h4>Realiseren:</h4>
                            <a href="/internships/teqplay/realiseren1">
                                <li className={"liLink"}>
                                    Realiseren 1
                                </li>
                            </a>

                            <a href="/internships/teqplay/realiseren2">
                                <li className={"liLink"}>
                                    Realiseren 2
                                </li>
                            </a>

                            <h4>Skills & attitude</h4>
                            <a href="/internships/teqplay/skills1">
                                <li className={"liLink"}>
                                    Skills 1
                                </li>
                            </a>

                            <a href="/internships/teqplay/skills2-3">
                                <li className={"liLink"}>
                                    Skills 2 & 3
                                </li>
                            </a>
                        </ul>

                        <ul>
                            <h3>Dagboek</h3>
                            <a href={Dagboek} target="blanc"><li className="liLink">Dagboek</li></a>
                        </ul>
                    </div>
                </div>
            </div>
        );

    }
}