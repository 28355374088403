import React from 'react';
import notFound from './notFound'
import ProjectDetail from './items/projects/projectsDetail';
import projects from '../data/projects'

const Project = ({ match }) => {
    var projectName = match.params.name
    console.log(projectName)
    console.log(projects[projectName])
    if(!projectName) return notFound

    return (
        <div>
            <ProjectDetail project={projects[projectName]} />
        </div>
    );
}



export default Project;