import React, { Component } from 'react'
import tesla from '../../../assets/img/tesla.png';
import layout from '../back'

export default class Teqplay extends Component {
    constructor() {
        super()
        this.state = {
            compententie: ""
        }
    }

    render() {
        return (
            <div className="content">
                {layout}
                <div className="Pheader">Tesla Nederland B.V.</div>
                <div className="uneven">
                    <div className="internItem">
                        <h3>Mijn stage en taken</h3>
                        <p>
                            Tesla Nederland b.v. was in mijn eerste stage die ik liep tijdens mijn MBO niveau 4. De stage was 10 weken lang in mijn 2e jaar.
                            Tijdens mijn stage heb ik veel geleerd over hoe het gaat in een bedrijf en heb ik verschillende takeng gedaan.
                        </p>

                        <p>
                            Ik ben daar naar een klant gestuurd en ik moest zelf in gesprek met de klant over een website en heb deze website daarna met succes
                             gebouwd voor de klant.

                            Naast deze taak heb ik ook nog veel mensen geholpen als een soort helpdesk en een paar computers in elkaar gezet.
                        </p>
                    </div>
                    <div className="internFoto">
                        <img src={tesla} alt="foto" className="circle" />
                    </div>

                    <div className="internItem">
                        <h3>Wat heb ik geleerd?</h3>

                        <p>
                            Tijdens mijn stage heb ik me veel ontwikkeld, niet persé op het programmeren, maar meer de sociale aspecten van het vak.
                            Door het praten met klanten voor de website en helpdesk heb ik veel geleerd hoe ik daarmee moest omgaag.
                        </p>

                        <p>
                            Voor mijn eerste stage was dit echt een perfecte stage, omdat ik met deze stage naar mijn gevoel een goede basis heb opgezet. 
                            Wat veel heeft geholpen met de stages daarna.
                        </p>

                        <h4>Gebruikte talen/frameworks</h4>
                        <ul>
                            <li>WordPress</li>
                            <li>HTML & css</li>
                        </ul>
                    </div>
                </div>
            </div>
        );

    }
}