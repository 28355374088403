import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { Kotlin } from './proof/Kotlin';

import feedback4 from '../../../../../assets/img/proof/feedback/prototype.png'

import './proof/proof.css'

export default class Leerdoel1 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Leerdoel 2</h2>
                <h3>Over het leerdoel</h3>
                <p>
                    Aan het einde van mijn stage ben ik een in staat om in Kotlin en/of andere backend taal een
                    project te voltooien.
                    Dit is een standaard leerdoel voor eens stage. Ik wil graag Kotlin leren en dit naar het niveau te
                    brengen wat nodig is als junior software engineer en om mee te werken in een bedrijf. Dit zal
                    goed staan op mijn CV en zal goed zijn voor mijn groei in het vakgebied.
                    Het leerdoel zal ik halen met mijn Riverscout project. Dit project kregen we de mogelijkheid zelf
                    te kiezen voor onze programmeertaal en zijn hiervoor voor Kotlin gegaan.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: feedback4,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Dit leerdoel had ik voor mijn stage opgesteld met de gedachte dat ik een backend programmeur wilde worden.
                    Tijdens mijn stage ben ik echter gewisseld van backend naar frontend.
                </p>
                <p>
                    Aan het begin van de stage heb ik gewerkt met Kotlin en hier kon na een aantal weken al goed mee overweg.
                    Ik verbaasde mezelf dat ik Kotlin zo snel op pakte en was hier erg tevreden over.
                    Mijn Kotlin kennis is goed en ik kan hier nu een project mee bouwen.
                </p>

                <h2>Persoonlijke groei</h2>
                <p>
                    Toen ik hier begon met mijn stage had ik nog niet gehoord van de programmeer taal Kotlin.
                    Na mijn stage kan ik nu programmeren in Kotlin. Ik heb de eerste helft van mijn stage gewerkt aan de backend van het project 
                    en tijdens het bouwen van de backend heb ik geleerd hoe ik moet programmeren met Kotlin.
                </p>
                <p>
                    Ik heb leren programmeren in Kotlin door veel onderzoek te doen en veel proberen met de taal. 
                    Ik heb veel website gebruikt om deze informatie te verkrijgen. Websites die ik gebruikt heb:
                    
                </p>
                <ul>
                    <li><a className="contains-a-blue" href="https://kotlinlang.org/docs/reference/" target="blanc">Kotlins documentatie</a></li>
                    <li><a className="contains-a-blue" href="https://www.tutorialspoint.com/kotlin" target="blanc">Kotlins tutorialspoint</a></li>
                </ul>
                <p>
                    Ik kan nu met Kotlin een project beginnen en afmaken, door de nieuwe kennis die ik heb verkregen tijdens deze stage.
                </p>

                <h2>Bewijs</h2>
                {Kotlin}
            </div>
        );
    }
}