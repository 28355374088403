import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { Samenwerken } from './proof/Samenwerken';

import feedback1 from '../../../../../assets/img/proof/feedback/samenwerking.png'
import feedback2 from '../../../../../assets/img/proof/feedback/scrum.png'

import './compententie.css'

export default class Leerdoel1 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Leerdoel 1</h2>

                <h3>Over het leerdoel</h3>
                <p>
                    Aan het einde van mijn stage kan ik een groot project, zoals het Riverscout project bij een
                    bedrijf uitvoeren en door de scrum methode, die het bedrijf hanteert, samenwerking met andere
                    perfectioneren.
                    Ik wil dit leren omdat in ieder bedrijf dat scrum (agile) hanteert dit op een andere manier gedaan
                    wordt. Door jezelf te leren aan te passen naar de veranderingen wordt het makkelijker om
                    samen te werken met verschillende mensen op verschillende manieren.
                    Dit leerdoel moet te halen zijn met mijn taken binnen het bedrijf. Elke ochtend om stipt 09.30
                    hebben we een stand up waarin ieder verteld wat hij/zij gedaan heeft gaat doen en of hij/zij
                    ergens tegen aan loopt.
                    Ik zal dit laten zien door het Riverscout project goed af te ronden met een goede samenwerking
                    met de andere stagiair.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: feedback1,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <ImageZoom
                    image={{
                        src: feedback2,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Ik vind dat ik voor dit leerdoel veel heb geleerd tijdens mijn stage, zowel mijn samenwerking is veel verbeterd ten opzichte van voor mijn stage.
                    Het werken met scrum verschilde ging ook steeds beter, aan het begin had ik wat moeite door dat het chaos werd 
                    in mijn hoofd als ik alles moest onthouden, maar dankzij het opschrijven wat ik gedaan had kreeg ik meer rust en ging de stand-up gelijk beter.
                    De samenwerking met de scrum methode is goed gegaan hiervoor hebben we een mooi project opgeleverd waar we ook na onze stage aan verder kunnen 
                    bij Teqplay.
                </p>

                <h2>Persoonlijke groei</h2>
                <p>
                    Tijdens mijn stage ben ik veel gegroeid in mijn samenwerking en met het werken met scrum. 
                    Mijn samenwerking met Maurice van Veen liep goed waardoor het project ook goed liep.
                    Met samenwerken ben ik veel gegroeid in het overleggen met andere, het afstemmen van afspraken en overall het samenwerken met een ander. 
                    Ik vond het ook leuk om andere af en toe te helpen als ik dat kon.
                </p>
                <p>
                    Ik had in het begin wat moeite met het praten in de stand-up. Als ik vertelde wat ik gedaan had en ging doen onstont
                    er een soort chaos en verliep dat wat moeizaam. Al snel had ik door dat als ik het op een briefje schreef ik hier veel meer rust in kon vinden
                    en verliepen mijn stand-up momenten veel beter.
                </p>

                <h2>Bewijs</h2>
                {Samenwerken}

                <h3>Het uitvoeren van een project</h3>
                <p>
                    Tijdens de stage hebben we gewerkt aan het RiverScout project. Dit project starten als een puur backend project waar Maurice en ik ook mee wilde werken.
                    Na een aantal weken ben ik overgestapt naar de frontend om het toch maar een keer te proberen. Dit vond ik onverwachts toch erg leuk en zo ben ik
                    verder gegaan aan de frontend applicatie van RiverScout.
                </p>
                <a href="/internships/teqplay/realiseren1#bewijs" className="link">
                    Klik hier om de competentie realiseren 1 te zien, waarin ik het project laat zien.
                </a>
            </div>
        );
    }
}