import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

import info1 from '../../../../../../assets/img/proof/Cypress/info1.png'
import info2 from '../../../../../../assets/img/proof/Cypress/info2.png'
import info3 from '../../../../../../assets/img/proof/Cypress/info3.png'

import draw1 from '../../../../../../assets/img/proof/Cypress/draw1.png'
import draw2 from '../../../../../../assets/img/proof/Cypress/draw2.png'
import draw3 from '../../../../../../assets/img/proof/Cypress/draw3.png'
import draw4 from '../../../../../../assets/img/proof/Cypress/draw4.png'

import nav1 from '../../../../../../assets/img/proof/Cypress/nav1.png'

import infoVid from '../../../../../../assets/img/proof/Cypress/vids/info.mp4'
import drawVid from '../../../../../../assets/img/proof/Cypress/vids/draw.mp4'
import navVid from '../../../../../../assets/img/proof/Cypress/vids/nav.mp4'

import unit1 from '../../../../../../assets/img/proof/Junit/unit1.png'
import unit2 from '../../../../../../assets/img/proof/Junit/unit2.png'
import succes from '../../../../../../assets/img/proof/Junit/succes.png'

const fotoWidth = '50em'

export const TestRapport =
    <div>
        <h2>TestRapport</h2>

        <div>
            <h3>Cypress</h3>
            <p>
                In het project test ik met doormiddel van Cypress. Dit document zal verschillende tests laten zien, het verwachte resultaat en het resultaat.
            </p>
            <p>
                In de foto's zal er veel code te zien zijn. In de code staat <code>cy.wait()</code>.
                Dit zorgt ervoor dat Cypress een aantal miliseconden wacht voor dat hij verder gaat.
                De reden dat dit er in staat is om te wachten tot het resultaat verschijnt en als het als
                <code> {"// cy.wait()"} </code> staat betekend dit dat het voor het maken van video's is.
            </p>

            <h4>Testen van de Info tool</h4>

            <ImageZoom
                image={{
                    src: info1,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
            <p>
                In de foto hier boven zie je een test geschreven in Cypress. Deze test loopt door de frontend heen.
                Hij begint bij het testen van het aanzetten van een ander thema waardoor de map een donker thema zou moeten krijgen.
            </p>

            <ImageZoom
                image={{
                    src: info2,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
            <p>
                Na het thema te veranderen gaat Cypress testen om de filters te openen en om een source uit te schakelen.
                Hier is zou visueel te zien zijn dat er polylines verdwijnen of bij het aanzetten verschijnen.
            </p>

            <ImageZoom
                image={{
                    src: info3,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
            <p>
                De laatste test zorgt er voor dat Cypress op een polyline klikt en dat er dan de informatie popup verschijnt.
                Er wordt dan gecheckt of de popup wel verschijnt.
            </p>

            <div>Test resultaat: </div>
            <video controls>
                <source src={infoVid} type="video/mp4" />
            </video>

            <p>
                In de bovenstaande video zie je Cypress de tests uitvoeren om de Info tool en de settings te testen. De tests zijn succesvol. 
                In de Cypress test doorloopt hij de scripts en volgt hij de exacte instructies. Dit zijn de stappen die iemand die handmatig zou testen ook zou doorlopen.
            </p>
        </div>

        <div>
            <h4>Testen van teken tool</h4>

            <ImageZoom
                image={{
                    src: draw1,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <p>
                In de bovenstaande foto zie de eerste UI test van de teken tool.
                Deze test kijkt of hij in UI een een reeks aan punten kan typen en deze kan bekijken en daarna kan verwijderen.
                Dit test of je dus je aanpassingen of nieuwe punten van bekijken en weer kan discarden als het toch niet goed is.
            </p>

            <ImageZoom
                image={{
                    src: draw2,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <p>
                In de tweede test voert hij weer een een aantal punten in, maar dit keer slaat hij ze op.
                Nadat deze zijn opgeslagen opent hij het formulier dat hem naar de backend/database kan sturen.
                In het formulier voert hij wat informatie in en drukt dan op Save. In het confirmatie scherm stopt hij door op cancel te drukken.
                (Dit zodat de database niet vervuilt wordt.)
            </p>

            <ImageZoom
                image={{
                    src: draw3,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <p>
                De derde test is heel kort, want deze checkt of hij de lijn kan verwijderen.
                Het resultaat zal visueel te zien zijn door dat de lijn verdwijnt.
            </p>

            <ImageZoom
                image={{
                    src: draw4,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
            <p>
                De vierde en laatste test is de test die checkt of er nieuwe punten toegevoegd kunnen worden.
                Het belangrijke is dat de nieuwe punten ook zichtbaar worden op de map, dit wordt dan weer visueel te zien.
            </p>

            <div>Test resultaat: </div>
            <video controls>
                <source src={drawVid} type="video/mp4" />
            </video>

            <p>
                In de bovenstaande video zie je de tests van de teken tool. 
                Deze tests zijn ook allemaal succesvol en dit gedeelte van de applicatie werkt zoals hij moet werken.
            </p>
        </div>

        <div>
            <h4>Testen van navigatie tool</h4>
            <ImageZoom
                image={{
                    src: nav1,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
            <p>
                De navigatie tool heeft maar 1 test. Dit is omdat de navigatie tool nog redelijk nieuw is in het systeem en hier nog veel veranderingen aan gaan komen.
                In de test wordt er een begin en eind-punt op de map gezet, daarna geeft Cypress aan dat het routes wil met een bepaalde CEMT klasse.
                Als dat allemaal is ingevuld maakt Cypress de call naar de backend om de routes terug te krijgen.
            </p>

            <div>Test resultaat: </div>
            <video controls>
                <source src={navVid} type="video/mp4" />
            </video>

            <p>
                In de navigatie tool is de test ook succesvol. Hij krijgt succesvol routes terug.
            </p>
        </div>

        <div>
            <h3>JUnit</h3>
            <p>
                Voor mijn stage begon heb ik ik de zomervakantie vele unit tests geschreven in Java test framework genaamd JUnit.
                De unit tests worden geschreven om de functionaliteiten van het platform te testen. Als iemand aan een functie gewerkt heeft,
                worden de tests uitgevoerd. Als deze succesvol zijn dan kan het worden gepushed en als het niet succesvol is dan weet men dat de aangewerkte
                functie niet meer werkt zoals hij moet werken.
            </p>

            <p>
                Hier onder zie je 2 voorbeelden van unit tests uit het platform en laatste foto is een test van alle unit test uit dat bestand.
            </p>

            <ImageZoom
                image={{
                    src: unit1,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <ImageZoom
                image={{
                    src: unit2,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <ImageZoom
                image={{
                    src: succes,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
        </div>


    </div>