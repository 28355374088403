import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

import java from '../../../../../../assets/img/proof/Advies/java.png'
import kotlin from '../../../../../../assets/img/proof/Advies/kotlin.png'

export const AdviesRapport =
    <div>
        <h3>Advies rapport</h3>

        <p>
            In ons project zijn een aantal keuzes mogelijk. In dit verslag wordt duidelijk gemaakt welke opties er zijn.
            Wat de voordelen zijn van deze keuzes en welke ik aanraad.
            Bij het advies rapport zal de hoofdvraag 'Hoe kunnen we routes genereren voor schepen om over te kunnen navigeren'
        </p>

        <p>
            Om deze hoofdvraag te beantwoorden moeten er een aantal overwegingen gemaakt worden. 
            Deze overwegingen zijn welke programmeer taal, welk routing algoritme en welke bronnen we gaan gebruiken om de grafiek te vullen.

        </p>

        <h4>Programmeer taal</h4>
        <p>
            Toen we het RiverScout project kregen kwam daarbij ook de keuze in programmeer taal.
            Binnen Teqplay gebruiken ze in de backend de talen Java en Kotlin.
        </p>

        <h5>Onderzoek & analyse</h5>

        <p>
            In de keuze voor een programmeer taal zijn er vele opties. Opties zoals Java, C#, Python
            of talen waar ik en mijn groepsgenoot (Maurice van Veen) nog niet mee gewerkt hadden, zoals Kotlin.
        </p>

        <p>
            Aangezien er bij Teqplay ondersteuning is op de talen Java en Kotlin. Besloten Maurice en ik te kiezen uit deze twee talen.
        </p>

        <h5>Java:</h5>

        <p>
            Java is een al wat oudere taal waar Maurice en ik al bekend mee waren.
            We hadden hier al projecten in gemaakt en zouden met deze taal snel aan de slag kunnen.
        </p>

        <h5>Kotlin:</h5>

        <p>
            Kotlin was een taal waar ik voor mijn stage nog nooit van gehoord had.
            Aangezien dit een overweging was heb ik er wat tijd in gestoken om de taal een beetje door te nemen.
            Na een week testen in Kotlin en wat kleine test projecten op te zetten, kwam ik er achter dat het het net als Java werkt,
            maar dan op een moderneren manier. Mijn idee bij Kotlin was dat het erg leek op TypeScript gemixt met Python.
        </p>

        <ImageZoom
            image={{
                src: java,
                alt: 'Foto',
                style: { width: '25em' }
            }}
        />

        <ImageZoom
            image={{
                src: kotlin,
                alt: 'Foto',
                style: { width: '25em' }
            }}
        />

        <p>
            In de bovenstaande foto's zie je het verschil tussen een Java en een Kotlin class. 
            De Java (linker foto) heeft meer regels en heeft meer karakters nodig om het zelfde te bereiken als de Kotlin class (rechter foto)
        </p>

        <h5>Mijn advies</h5>
        <p>
            In de keuze in de programmeer taal gaat mijn advies uit naar Kotlin.
            Dit vanwegen het feit dat je op een zelfde manier kan werken als in Java, maar dan met een makkelijkere manier van opschrijven. 
            Een andere reden is, dat Maurice en ik met Kotlin een nieuwe moderne taal leren.
        </p>


        <h4>Routing algoritme</h4>
        <p>
            Om over een grafiek van lijnen te kunnen navigeren waren er algoritmes nodig. Hiervoor vonden we twee opties.
            Optie één was <a href="https://www.graphhopper.com/" style={{color: 'blue'}} target="blanc">Graphhopper</a> en 
            optie twee was <a href="https://github.com/RoutingKit/RoutingKit" style={{color: 'blue'}} target="blanc">Routing kit</a>
        </p>

        <h5>Onderzoek & analyse</h5>

        <h5>Graphhopper</h5>

        <p>
            Graphhopper is een library geschreven in Java. De combinatie van Java en Kotlin is erg makkelijk, 
            je kan namelijk een Java class gebruiken in je Kotlin applicatie. Dit is een enorm voordeel aan deze library.
            Graphhopper is een grote open source applicatie die veel verschillende mogelijk heden heeft tot navigeren met autos, fiets, lopend enz...
            Het nadeel van Graphhopper is dat er geen standaard optie is om met een boot te navigeren. 
            Hiervoor zouden we een eigen grafiek moeten bouwen die te gebruiken is door Graphhopper.
            Na veel testen hadden we een simpelen grafiek opgezet die Graphhopper kon gebruiken.
        </p>

        <h5>Routing Kit</h5>

        <p>
            Routing Kit is een library geschreven in C++. 
            Dit is een nadeel vanwegen het combineren van Kotlin met C++, wat niet mogelijk is tenzij je een externe tool hiervoor gebruikt.
            Deze tool zal dan een Kotlin functie aan een C++ functie kunnen koppelen en zo de data kunnen doorgeven aan elkaar.
            Na veel testen hiermee kwam ik al snel achter dat dit erg moeilijk zou gaan worden om te gaan gebruiken in RiverScout.
            Het voordeel van deze library zou zijn dat Teqplay een van de makers van de library kent.
        </p>


        <h5>Mijn advies</h5>
        <p>
            Mijn advies gaat uit naar Graphhopper. Dit omdat de in Java geschreven library goed kan samenwerken met ons Kotlin project.
            Graphhopper heeft alle tools die nodig zijn om het project te gaan opzetten.
        </p>


        <h4>Bronnen om de grafiek mee te bouwen</h4>
        <p>
            Voor het bouwen van de grafiek is er data nodig om de grafiek te bouwen. Deze dat kan van verschillende bronnen komen.
            De verschillende bronnen zouden ook gecombineerd kunnen worden. 
            Bronnen waar naar gekeken gaat worden zijn de RIS (River Information Services)en FIS (Fairway Information Service) van Rijkswaterstaat.
        </p>

        <h5>Onderzoek & analyse</h5>
        <p>
            In het analyseren van de data is het het best om het visueel te kunnen zien. 
            Maurice en ik besloten als eerste de RIS te gebruiken. Met de RIS konden we ons eerste prototype opzetten, maar hier waren nog wel wat problemen mee.
            de problemen die in de RIS voor kwamen waren stukken van routes die over land gingen dit kwam omdat veel rivieren waren niet gedetailleerd genoeg.
            Dit zorgde voor veel slordige fouten in de grafiek.
        </p>
        <p>
            Toen we gingen kijken naar de FIS kwamen we er achter dat hier verschillende bronnen in zatten die we goed konden combineren. 
            Na wat testen kwamen we er al snel achter dat de FIS veel meer detail had als de RIS. 
        </p>

        <h5>Mijn advies</h5>
        <p>
            Na het gebruiken van beide gaat mijn advies uit naar de FIS. Dit omdat het gewoon veel meer detail heeft en meer opties heeft.
            Het combineren van de twee data sources ging ook niet goed, omdat we dan alle data ook de fouten uit de RIS samen met de goede data van de FIS 
            zouden combineren. Dit zorgt er voor dat de grafiek misplaatste punten van de RIS zou gebruiken om de routes te tekenen. 
        </p>

        <h5>Conclusie</h5>
        <p>
            Na het onderzoek is mijn advies om het project te gaan bouwen met Kotlin en het Springboot framework. 
            Voor het routing algoritme is mijn advies om voor Graphhopper te kiezen en voor de bronnen raad ik aan om het FIS te gebruiken.
            Deze keuzes kunnen goed samen gebruikt worden Kotlin met Springboot kan goed samenwerken met het in Java geschreven Graphhoper.
            Kotlin is een goede taal om data in te verwerken en kan dus goed overweg met de gekozen databronnen van het FIS.
        </p>
    </div>