import React, {Component} from 'react'


import teqplay from '../../../../assets/img/teqplay.png';

export default class TeqplayInfo extends Component {
    render() {
        return (
            <div>
                <div className="internItem">
                    <p>
                        Teqplay is een bedrijf, gevestigd in de Van Nellefabriek in Rotterdam. Teqplay is opgezet door
                        de twee founders Leon Gommans en Richard van Klaveren.<br />
                        Bij Teqplay wordt veel gebruik gemaakt van data. Door de verzamelde data goed te combineren
                        met elkaar, kunnen verschillende dashboards gemaakt worden voor hun gebruikers. De
                        dashboards zijn voor verschillende aspecten rond de waterwegen, denk bijvoorbeeld aan
                        locaties van schepen en bruggen.
                    </p>
                    <p>
                        Bij Teqplay werken ze in sprints van twee weken en aan het begin van deze sprint wordt een
                        doel opgesteld wat we aan het einde van de sprint moeten hebben behaald. Aan het einde van
                        de sprint wordt een demo gegeven aan iedereen van Teqplay, op deze demo kan ik veel
                        feedback krijgen vanuit de groep.
                    </p>
                    <p>
                        Tijdens mijn stage word ik vooral ondersteund door twee developers, Joris en Babette. Zij zullen
                        mij ondersteunen als ik vragen heb en zullen mij ook code-reviews geven hierdoor krijg ik snel
                        feedback op mijn geschreven code.
                    </p>
                    <p>
                        Binnen Teqplay is de sfeer informeel. We werken in één ruimte en iedereen helpt elkaar, door
                        de dagelijkse stand-up gaat dit ook makkelijker.
                    </p>
                    <p>
                        Mijn stage begon op 27 augustus 2018 en zal ongeveer eindigen op 8 februari 2019.
                    </p>

                </div>
                <div className="internFoto">
                    <img src={teqplay} alt="foto" className="circle" />
                </div>
            </div>
        );
    }
}