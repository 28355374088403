import React from 'react'
import ImageZoom from 'react-medium-image-zoom'
import './proof.css'

import riverscout from '../../../../../../assets/img/proof/riverscout.png'
import sideDraw from '../../../../../../assets/img/proof/sidedraw.png'
import selectedRoute from '../../../../../../assets/img/proof/selectedRoute.png'
import save from '../../../../../../assets/img/proof/save.png'
import filter from '../../../../../../assets/img/proof/filter.png'
import filterOff from '../../../../../../assets/img/proof/filterOff.png'
import markersGiff from '../../../../../../assets/img/proof/gifs/markers.gif'
import inputMarkers from '../../../../../../assets/img/proof/gifs/inputMarkers.gif'
import moveMarkers from '../../../../../../assets/img/proof/gifs/moveMarkers.gif'
import navigationGif from '../../../../../../assets/img/proof/gifs/Navigation.gif'
import navPopup from '../../../../../../assets/img/proof/navigatie/popup.png'
import drawPoly from '../../../../../../assets/img/proof/navigatie/drawPoly.png'

const fotoWidth = '50em'

export const riverScout =
    <div>

        <div className="contains-a">
            <ul>
                <h3>Requirements:</h3>

                <li>
                    <a href="#algemeen">Algemenen requirements:</a>

                    <ul>
                        <li>
                            <a href="#algemeen-visueel">Inzien van het routenetwerk/grafiek door polylines te tekenen op een map.</a>
                        </li>
                        <li>
                            <a href="#algemeen-settings">Aanpassen van settings.</a>
                        </li>
                    </ul>
                </li>

                <li>
                    <a href="#info-tool">Info tool:</a>
                    <ul>
                        <li><a href="#info-click" >Polylines aanklikken om info in te zien.</a></li>
                        <li><a href="#info-filteren">Filteren op polyline sources.</a></li>
                        <li><a href="#info-aanpassen">Informatie aanpassen van de grafiek.</a></li>
                    </ul>
                </li>

                <li>
                    <a href="#draw-tool">Draw tool:</a>
                    <ul>
                        <li><a href="#draw-tekenen">Tekenen van punten.</a></li>
                        <li><a href="#draw-verschuiven">Verschuiven van punten.</a></li>
                        <li><a href="#draw-save">Opslaan van getekende lijnen.</a></li>
                    </ul>
                </li>

                <li>
                    <a href="#nav-tool">Navigation tool:</a>
                    <ul>
                        <li><a href="#nav-punten">Start, via en eindpunten op de map zetten. (markers)</a></li>
                        <li><a href="#nav-verschuiven">De markers verplaatsen door ze te verslepen.</a></li>
                        <li><a href="#nav-info">Informatie betreft CEMT klasse mee geven.</a></li>
                        <li><a href="#nav-tekenen">Route(s) tekenen over de grafiek.</a></li>
                    </ul>
                </li>
            </ul>
        </div>

        <div id="algemeen" className="section">
            <h3>Algemeen</h3>

            <h4 id="algemeen-visueel">Visueel maken van Polylines</h4>
            <p>
                Zoals in de foto te zien is zie je paarse polylines over de map getekend.
                Deze lijnen vormen ons netwerk en dankzij de tool kunnen we deze visualiseren.
            </p>
            <ImageZoom
                image={{
                    src: riverscout,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />


            <h4 id="algemeen-settings">Aanpassen van settings.</h4>
            <p>
                Dit is het settings menu, waar de settings van project komen te staan.
                Hier kan de gebruiker bijvoorbeeld aangeven ik wil een donkere map of ik wil bruggen zien.
            </p>
            <ImageZoom
                image={{
                    src: sideDraw,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
        </div>

        <div id="info-tool" className="section">
            <h3>Info tool</h3>
            <h4 id="info-click">Polylines aanklikken om info in te zien.</h4>
            <p>
                In de info tool kan men op de lijnen klikken en dan komt er een pop-up met daarin alle gegevens die we hebben over de lijn.
            </p>
            <ImageZoom
                image={{
                    src: selectedRoute,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="info-filteren">Filteren op polyline sources.</h4>
            <p>
                Met de filter tool, kan de gebruiker aangeven wat hij wil zien en of hij
                bijvoorbeeld een kleur wil geven aan de waterwegen die van een bepaalde source komen.

                In het voorbeeld hier onder zie je dat de 'MAXIMUM_DIMENSIONS' source een groene kleur gekregen heeft en dus verschilt van de rest.
            </p>
            <ImageZoom
                image={{
                    src: filter,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <p>
                Je kan met deze tool ook aangeven of je bepaalde sources niet wil zien. In het voorbeeld hier onder zie je
                dat 'CEMT_CLASS' en 'PLEASURE_CRAFT' uit geschakeld zijn en dat de grafiek er wat kariger uitziet.
            </p>
            <ImageZoom
                image={{
                    src: filterOff,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="info-aanpassen">Informatie aanpassen van de grafiek.</h4>

            <p>
                Dit is een requirement die nog niet in de applicatie zit, maar er wel in gaat komen in de toekomst.
                Het idee is om de grafiek die we van allerlei sources maken te kunnen gaan aanpassen en voorzien van nieuwe data.
            </p>
            <p>
                De reden hiervoor is dat de datasources die we hebben niet altijd consequent
                zijn en er af en toe punten niet goed staan of dat de informatie niet compleet of goed is.
            </p>
            <p>
                Deze requirement zit nog niet in de applicatie verwerkt,
                omdat we er nog niet over uit zijn hoe we dit gaan doen aan de backend en frontend kant.
                Een andere reden is dat ik er gewoon nog niet aan toegekomen ben om hier over na te denken omdat er op
                dit moment andere prioriteiten zijn om in de frontend tool te verwerken.
            </p>
        </div>

        <div id="draw-tool" className="section">
            <h3>Draw tool</h3>
            <h4 id="draw-tekenen">Tekenen van punten.</h4>
            <p>
                In de draw tool is het belangrijkste dat de lijnen kunnen worden getekend. Dit kan simpel door op de map te klikken waardoor er markers verschijnen.
                De markers vormen samen een polyline.
                Je kan ook een lijst van coördinaten invoeren in het tekst vak en door die door te voeren verschijnen alle punten automatisch.
            </p>

            <p>
                In de onderstaande Gif, zie je hoe de lijnen worden getekend door dat de gebruiker klikt op de map.
            </p>
            <ImageZoom
                image={{
                    src: markersGiff,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <p>
                In de onderstaande Gif, zie je hoe de lijnen worden getekend door dat de gebruiker een lijst aan coördinaten in het invoerveld aangeeft.
            </p>
            <ImageZoom
                image={{
                    src: inputMarkers,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />


            <h4 id="draw-verschuiven">Verschuiven van punten.</h4>
            <p>
                De markers kunnen worden verschoven door ze te verslepen naar een andere locatie op de map.
                In de Gif hier onder zie je hoe de gebruiker een marker verplaatst.
                Als de gebruiker een middenpunt versleept veranderd deze automatisch naar een nieuwe marker.
            </p>

            <p>
                Marker is getekend als een rood punt met daar om heen een cirkel.<br />
                Een tussen marker is getekend als alleen een rood puntje. (Deze zijn er om het makkelijk te maken om lijnen te verbeteren)
            </p>
            <ImageZoom
                image={{
                    src: moveMarkers,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="draw-save">Opslaan van getekende lijnen.</h4>
            <p>
                In de foto hieronder zie je een formulier dat ingevuld wordt als de lijn getekend is en opgeslagen moet worden.
                De invoervelden van het formulier zorgen dat de getekende lijnen informatie mee krijgen die gebruikt wordt om routes te plannen.
                Deze informatie bevat vaak wat voor klasse boot er over de route/lijn kan varen.
            </p>
            <ImageZoom
                image={{
                    src: save,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

        </div>

        <div id="nav-tool" className="section">
            <h3>Navigation tool</h3>
            <h4 id="nav-punten">Start, via en eindpunten op de map zetten. (markers)</h4>
            <p>
                De navigatie tool is bedoeld om de getekende lijnen te gaan testen.
            </p>

            <ImageZoom
                image={{
                    src: navigationGif,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="nav-verschuiven">De markers verplaatsen door ze te verslepen.</h4>
            <p>
                Dit werkt het zelfde als de markers verplaatsen in de <a href="#draw-verschuiven" style={{color: 'blue'}}>teken tool</a>.
                Deze functie zorgt er voor dat je makkelijk de route kan aanpassen. 
            </p>

            <h4 id="nav-info">Informatie betreft CEMT klasse mee geven.</h4>
            <p>
                In de foto hier onder zie je het informatie formulier voor het navigeren.
                In dit formulier kan er informatie ingevuld worden. Deze informatie wordt mee gestuurd naar de backend en wordt daar gebruikt om een route op te zetten.
                De informatie meegeven is belangrijk voor het testen van nieuw getekende lijnen. Deze lijnen hebben een CEMT klasse mee gekregen 
                en hier kan getest worden of het mee genomen wordt in het berekenen van de route.
            </p>

            <ImageZoom
                image={{
                    src: navPopup,
                    alt: 'Foto',
                    style: { width: '25em' }
                }}
            />

            <h4 id="nav-tekenen">Route(s) tekenen over de grafiek.</h4>
            <p>
                Als alles alle informatie wordt gestuurd naar de backend om de routes te berekenen krijgt 
                de frontend hier een bericht van terug met daarin alle polylines die de mogelijke routes vertegenwoordigen.
                Je ziet in de onderstaande foto allemaal polylines met verschillende kleuren. Deze kleuren laten verschillende routes zien.
            </p>
            <ImageZoom
                image={{
                    src: drawPoly,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
        </div>
    </div>