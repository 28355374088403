import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { TechnologyStack } from './proof/TechnologyStack';
import technology from '../../../../../assets/img/proof/analyse/technologystack.png'

export default class Analyse3 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie Analyseren 3</h2>
                Over de competentie
                <p>
                    Je kunt een specificatie opstellen aan de
                    hand van een analyse.
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Een overzicht maken van high end design en
                    technology stack.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: technology,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    De keuzes in technieken was snel gemaakt, we hadden onze keuzes goed doorgenomen en een besluit gemaakt met zijn tweeën.
                    Door deze aanpak konden we snel aan de slag en hadden we al snel een goedwerkend prototype staan.
                    Deze aanpak ging erg goed. Maurice en ik wisten al snel hoe we de technieken moesten gebruiken.
                </p>

                <p>
                    Met de frontend keuzes ben ik geholpen door andere frontenders. Dit hielp mij met het project
                    opzetten want had nog nooit een project met ReactJS gemaakt.
                    De keuzes die ik voorgesteld kreeg waren technieken die ook binnen Teqplay gebruikt worden in de projecten.
                </p>

                <h2>Bewijs</h2>
                {TechnologyStack}
            </div>
        );
    }
}