import React from 'react'

export const Testplan =
    <div>
        <h2>Testplan</h2>

        <h4>Inleiding</h4>
        <p>
            In het frontend project heb ik veel handmatig moeten testen.
            Om dit grotendeels te automatiseren ben ik gebruik gaan maken van Cypress.
            In dit testplan zal ik een opzet maken hoe er getest gaat worden, hoe deze gemeten gaan worden en waar ze aan moeten voldoen.
        </p>

        <h4>Achtergrond informatie</h4>
        <div>Cypress:</div>
        <p>
            Cypress is een tool die UI (User Interface) tests automatisch uitvoert en aangeeft of deze succesvol zijn geweest.
        </p>

        <div>Handmatig testen:</div>
        <p>
            Tijdens het ontwikkelen van het project test ik het project veel handmatig door door het systeem heen te lopen. 
            Daarnaast loopt mijn teamgenoot (Maurice van Veen) zo nu en dan ook door het systeem heen om bugs op te sporen en geeft deze aan mij door.
        </p>

        <h4>Taken</h4>
        <div>Cypress:</div>
        <p>
            Cypress gaat ervoor zorgen dat het systeem na een update snel kan worden getest. 
            Cypress zal zoveel mogelijk opties in de UI testen.
            De taken die getest worden met Cypress hebben te maken met de requirements van de applicatie. 
            De requirements moeten 100% functioneel blijven zodat de applicatie gebruikt kan worden binnen het bedrijf.
            De onderstaande lijst met taken zorgt er voor dat alle requirements getest worden, zodat de gebruiker binnen Teqplay een werkzame applicatie kan gebruiken.
        </p>

        <p>Taken op een rijtje.</p>
        <ul>
            <li>Settings veranderen</li>
            <li>Op een polyline klikken voor de informatie</li>
            <li>Informatie scherm weg klikken</li>
            <li>Filteren van Sources</li>
            <li>Lijnen tekenen</li>
            <li>Lijnen Opslaan</li>
            <li>navigatie punten neer zetten</li>
            <li>Informatie mee geven met route opvragen</li>
        </ul>

        <div>Handmatig:</div>
        <p>
            Het handmatig testen zal naast Cypress de overige opties nalopen in de frontend. 
            Deze zal in loop van de tijd steeds minder worden als Cypress steeds meer zal gaan coveren
        </p>

        <h4>Test methodes</h4>
        <p>
            Het testen van de applicatie wordt uitgevoerd op kantoor. De tests zullen uitgevoerd worden na het aanpassen of toevoegen van functionaliteiten.
            Met Cypress wordt er gekeken hoe de tests uitgevoerd zijn en als er problemen zijn kan je daar in terug kijken waar het fout gaat. 
            Met het handmatig testen wordt er door de tester natuurlijk gelijk gekeken naar hoe de tests gaan en waar het fout gaat.
        </p>
        <p>Hoe observatie test omgeving.</p>

        <h4>Meten</h4>
        <div>Cypress:</div>
        <p>
            Met Cypress wordt er gekeken naar de tests, na dat de tests uitgevoerd zijn worden deze na gelopen en gekeken of alles goed gegaan is.
            Het belangrijke bij de tests is dat de UI werkt naar wat er verwacht wordt. 
            Cypress zal een error geven als hij een taak niet kan uitvoeren, omdat bijvoorbeeld de informatie popup niet verschijnt.
        </p>

        <div>Handmatig:</div>
        <p>
            Het handmatig testen wordt er gekeken naar de delen van het systeem dat Cypress niet test. 
            Als hier in getest wordt zal degene die het test door het systeem heen navigeren en kijken of de UI goed werkt en of de resultaten zijn zoals verwacht wordt.
        </p>

        <h4>Acceptatie test</h4>
        <p>
            Als er getest is dienen de test resultaten 100% succesvol te zijn. Als dit niet het geval is kan de applicatie niet naar de Master gepushed worden.
            De tests moeten 100% succesvol zijn, omdat als dat niet het geval is dan werkt de UI niet zoals hij zou moeten werken.
        </p>
    </div>