import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import requirement from '../../../../../assets/img/proof/feedback/requirement.png'
import { RequirementAnalyse } from './proof/RequirementAnalyse';

export default class Analyse2 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie Analyseren 2</h2>
                <h3>Info over de competentie</h3>
                <p>
                    Je kunt een requirements analyse
                    uitvoeren voor een (deel van een) software
                    systeem met verschillende belanghebbenden
                    en je houdt hierbij rekening met de
                    kwaliteitsstandaarden die gelden bij het
                    bedrijf.
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Als we de nieuwe sprint ingaan wordt er aan
                    het begin een requirement analyse uitgevoerd
                    voor de nieuwe features volgens de code
                    standaarden. De features zullen worden
                    bijgehouden op het Trello bord.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: requirement,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Het opstellen van requirements was in het begin erg lastig. Dit was omdat ik nog niet helemaal wist waar ik moest gaan beginnen.
                    Tijdens de eerste demo's kreeg ik hier goede begeleiding in en leerde ik hier veel van. Na een aantal sprints ging het steeds beter om
                    voor de demo's al een lijst met requirements op te stellen van wat de volgende stappen zouden gaan worden.
                </p>

                <h2>Bewijs</h2>
                {RequirementAnalyse}
            </div>
        );
    }
}