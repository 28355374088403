import React, { Component } from 'react'
import tesla from '../../../assets/img/parcsapp.png';
import layout from '../back'

export default class Parcsapp extends Component {
    constructor() {
        super()
        this.state = {
            compententie: ""
        }
    }

    render() {
        return (
            <div className="content">
                {layout}
                <div className="Pheader">Parcsapp</div>
                <div className="uneven">
                    <div className="internItem">
                        <h3>Mijn stage en taken</h3>
                        <p>
                            Parcsapp was mijn eindstage op het MBO. Ik was tijdens mijn stage vaak alleen en moest alles zelf oplossen.
                            Mijn taken kreeg ik van mijn stagebegeleider die mij aan het begin en einde van de dag belde.
                        </p>
                        <p>
                            Taken die ik tijdens deze stage gedaan heb verschilde per project. Ik heb bijvoorbeeld een website gemaakt waar de werknemers 
                            van een bedrijf zijn uren konden invullen en dat de uren gelijk naar de boekhoud tool door werden gestuurd.
                            Ik bouwde deze website in mijn eentje.
                        </p>
                        <p>
                            Naast deze opdracht heb ik ook gewerkt aan een demo product, waarin ik met een externe klant aan de slag ging.
                            Helaas ging de samenwerking tussen de klant en mijn baas niet goed waardoor na een aantal demo's de samenwerking stop werd gezet.
                        </p>
                    </div>
                    <div className="internFoto">
                        <img src={tesla} alt="foto" className="circle" />
                    </div>

                    <div className="internItem">
                        <h3>Wat heb ik geleerd?</h3>
                        <p>
                            Tijdens mijn stage moest ik alles zelf uit zoeken dit kwam omdat ik kon mijn begeleider 
                            niet altijd bereiken en hij had geen verstand van het programmeren in PHP. 
                        </p>
                        <p>
                            Ik heb hierdoor geleerd hoe ik zelfstandig moet werken en hoe ik mijn problemen moet oplossen.
                            Deze stage was als ik er op terug kijk ook een leermoment naar hoe je veel dingen niet moet aanpakken.
                        </p>
                    </div>
                </div>
            </div>
        );

    }
}