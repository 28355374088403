import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

import samenwerking from '../../../../../../assets/img/proof/feedback/samenwerking.png'

const fotoWidth = '25em'

export const Samenwerken = 
    <div>
        <h3>Samenwerking</h3>
        <p>
            Tijdens mijn stage heb ik samen gewerkt met Maurice van Veen aan het RiverScout project.
            Deze samenwerking is erg goed gegaan. Aan het begin van het project werkte we samen aan de backend van RiverScout.
            Hier werkte we goed samen en wisten we wat ieder ging doen, mee bezig was en gedaan had. Door deze samenwerking hebben we 
            een mooi project kunnen opleveren waar we na onze stage nog aan verder kunnen werken.
        </p>
        <ImageZoom
            image={{
                src: samenwerking,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />
    </div>