import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom"
import './App.css';

import home from "./components/home"
import internships from "./components/internships"
import notFound from "./components/notFound"
import projects from './components/projects';

class App extends Component {
  render() {
    return (
      <div>
        <div className="header">Portfolio</div>
            <BrowserRouter>
              <Switch>
                <Route path={process.env.PUBLIC_URL + '/'} component={home} exact />
                <Route path={process.env.PUBLIC_URL + '/internships/:name'} component={internships} />
                <Route path={process.env.PUBLIC_URL + '/projects/:name'} component={projects} />
                <Route component={notFound} exact/>
              </Switch>
            </BrowserRouter>
        <div className="footer">JProjects</div>
      </div>
    );
  }
}

export default App;
