import React from 'react'
// import ImageZoom from 'react-medium-image-zoom'

import './proof.css'

export const Libraries =
    <div>
        <h4>Gebruikte libraries</h4>
        <p>
            In ReactJS heb je vele libraries die je kunt gebruiken om je website mooier of betere functionaliteiten te geven.
            In RiverScout heb ik een aantal libraries gebruikt.
        </p>

        <ul>
            <li>LeafletJS</li>
            <li>Polyline decode/encode</li>
            <li>Cypress</li>
        </ul>

        <h5>LeafletJS</h5>
        <p>
            <a href="https://leafletjs.com/" className="contains-a-blue" target="blanc">LeafletJS</a> is een library waar je een map kan tekenen in je React website.
            In deze library zitten vele mogelijkheden zoals markers tekenen, polylines teken. Deze functionaliteiten kunnen we gebruiken om ons route netwerk 
            te tekenen/inzien, aanvullen en mogelijk in de toekomst aanpassen. 
        </p>

        <h5>Polyline decode/encode</h5>
        <p>
            Uit de backend krijgen we polylines, maar voor Leaflet heb ik coördinaten nodig om polylines te tekenen, hiervoor wordt deze library gebruikt.
            Met de decode functie creëer ik van de polyline een lijst van coördinaten deze kunnen dan weer gebruikt worden in Leaflet die deze encode naar een polyline.
        </p>

        <h5>Cypress</h5>
        <p>
            Dit is de testing UI library. Met de library automatiseer ik de tests in RiverScout. Deze tests zorgen ervoor dat men sneller de applicatie kan testen als 
            er veranderingen gedaan zijn in de UI of logica die met UI te maken heeft. 
        </p>
    </div>