import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { TestRapport } from './proof/TestRapport';

import tests from '../../../../../assets/img/proof/feedback/tests.png'

export default class Realiseren2 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie realiseren 2</h2>

                <h3>Info over de competentie</h3>
                <p>
                    Je maakt gebruik van testen en je maakt
                    gebruik van test-automatisering.
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Screenshots van unit tests, integratie tests
                    en een testplan/rapport
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: tests,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Het is erg belangrijk om een applicatie te testen. Hiervoor is het testplan wat ik heb opgesteld.
                    Dankzij dit testplan heb ik een overzicht wat er getest wordt en hoe er getest wordt.
                    De applicaties die gebouwd zijn zowel frontend en backend worden veel getest.
                    Deze tests zijn bijna allemaal geautomatiseerd.
                </p>

                <p>
                    Ik ben erg tevreden hoe de applicaties nu getest worden. De tests worden nog steeds aangepast en verbeterd waardoor
                    de applicaties steeds beter getest worden.
                </p>

                <h2 id="bewijs">Bewijs</h2>
                <p>Het testplan staat in de competentie <a href="/internships/teqplay/analyse4#bewijs" className="link">analyseren 4</a></p>
                <hr />
                {TestRapport}

                
            </div>
        );
    }
}