import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import feedback from '../docs/teqplay/FeedbackForm.pdf'
import './proof/proof.css'

import samenwerking from '../../../../../assets/img/proof/feedback/samenwerking.png'
import bespreken from '../../../../../assets/img/proof/feedback/bespreken.png'

export default class Skills23 extends Component {
    render() {
        return (
            <div className="competentie">
                <h3>Competentie Skills 3</h3>

                <h3>Info over de competentie Skills 2</h3>
                <p>
                    Je kunt je inwerken en aanpassen aan het
                    stagebedrijf betreft de werkwijze, regels en
                    procedures en bent daarnaast in staat om
                    hierop en op je werkzaamheden te reflecteren
                </p>

                <h3>Info over de competentie Skills 3</h3>
                <p>
                    Je kunt afgesproken werkzaamheden
                    zelfstandig (inzet, motivatie, respect en
                    dergelijke) uitvoeren en geeft voortgang en
                    eventuele knelpunten proactief aan
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Feedback van begeleiders door middel van
                    feedbackformulieren.
                </p>

                <h3>Bewijs</h3>
                <p>
                    Toen ik aan de begeleiders vroeg over de feedback formulieren, kwam Richard met het idee om ons eerst jezelf feedback te geven.
                    Na dat ik mezelf feedback had gegeven heeft Babette daarop gereageerd in het feedback formulier.
                    Dit zorgde ervoor dat we tijdens het gesprek dingen die verschillende konden worden toegelicht en besproken.
                    De dingen waar we het over eens waren werden kort besproken maar konden we snel mee verder.
                </p>

                <p>
                    De feedback formulieren zijn tijdens de stage samen opgezet met de andere stagiairs van Teqplay.
                    De andere stagiairs zijn:
                </p>
                <ul className="blue">
                    <li><a href="http://teqplay.derdejaarsstage.mauricevanveen.com/" target="blanc">Maurice van Veen</a></li>
                    <li><a href="http://catalinaandreita.nl/" target="blanc">Catalina Andreita-Carstoiu</a></li>
                    <li><a href="https://stevenkoerts.nl/" target="blanc">Steven Koerts</a></li>
                </ul>
                <p><a href={feedback} className="contains-a-blue" target="blanc" >Open feedback formulier</a></p>

                <p>Voorbeelden feedback formulier</p>
                <ImageZoom
                    image={{
                        src: samenwerking,
                        alt: 'Foto',
                        style: { width: '30vw' }
                    }}
                />

                <ImageZoom
                    image={{
                        src: bespreken,
                        alt: 'Foto',
                        style: { width: '30vw' }
                    }}
                />
            </div>
        );
    }
}