import React from "react";
import teqplay from '../assets/img/teqplay.png';
import tesla from '../assets/img/tesla.png';
import parcsapp from '../assets/img/parcsapp.png';
import coders from '../assets/img/coders.png';
import me from '../assets/img/me1.jpg'
import './component.css';

const Home = () => {
    return (
        <div className="content">
            <div className="uneven">
                <div className="Pheader">About</div>
                <div className="aboutItem">
                    <p>
                        Mijn naam is Joost Zonneveld en ik ben een 3e jaars Informatica student.
                    </p>

                    <p>
                        Ik ben bij deze opleiding gekomen door na mijn MAVO de opleiding Applicatie ontwikkeling te
                        gaan doen op het MBO niveau 4. Na het succesvol afronden van deze studie, wilde ik nog gaan
                        doorleren en kwam ik op de opleiding Informatica uit.
                    </p>
                    Wat voor talen heb ik geleerd tijdens mijn studies?
                        <ul>

                        <li>HTML & CSS</li>
                        <li>PHP</li>
                        <li>Python</li>
                        <li>C#</li>
                        <li>Typescript</li>
                        <li>Java</li>
                        <li>Kotlin</li>
                        <li>Java-script</li>
                    </ul>
                </div>

                <div className="aboutFoto">
                    <img src={me} alt="foto" className="circle" />
                </div>

                <div className="aboutItem">
                    <p>
                        Naast mijn studie doe ik aan basketbal, bij de vereniging The Hurricanes in Spijkenisse. Hier speel ik in het Heren 1 team.
                    </p>

                    <p>
                        Ik help veel bij de vereniging met het helpen bij evenementen of bij het trainen van andere teams. <br />
                        Op het moment geef ik geen training omdat het teveel tijd in neemt ik ik dat niet redt met mijn studie.
                    </p>
                </div>
            </div>
            {/* <div className="even">
                <div className="Pheader">Projects</div>

                <div className="item">
                    <a href="/projects/riverscout" className="circle">
                        <img src={defaultImg} alt="Foto" width="300" height="300" />
                    </a>
                    <div className="itemHeader">RiverScout</div>
                </div>
            </div> */}

            <div className="even">
                <div className="Pheader">Internships</div>

                <div className="item" >
                    <a href="/internships/tesla" className="circle" >
                        <img src={tesla} alt="Foto" width="300" height="300" />
                    </a>
                    <div className="itemHeader">Tesla Nederland b.v.</div>
                </div>

                <div className="item">
                    <a href="/internships/coders" className="circle">
                        <img src={coders} alt="Foto" width="300" height="300" />
                    </a>
                    <div className="itemHeader">Coders Academy</div>
                </div>

                <div className="item">
                    <a href="/internships/parcsapp" className="circle">
                        <img src={parcsapp} alt="Foto" width="300" height="300" />
                    </a>
                    <div className="itemHeader">Parcsapp</div>
                </div>

                <div className="item">
                    <a href="/internships/teqplay" className="circle">
                        <img src={teqplay} alt="Foto" width="300" height="300" />
                    </a>
                    <div className="itemHeader">Teqplay</div>
                </div>
            </div>
        </div>
    );
}

export default Home;
