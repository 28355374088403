import React from "react";


const NotFound = () => {
    return (
        <div className="content">
            404 path does not exists
        </div>
    );
}

export default NotFound;