import React, { Component } from 'react'

import './competenties/proof/proof.css'

import teqplay from '../../../../assets/img/teqplay.png';
import stageplan3 from './docs/teqplay/stageplan-v3.pdf'
import stageplan1 from './docs/teqplay/stageplan-v1.pdf'

export default class Stageplan extends Component {
    render() {
        return (
            <div>
                <div className="internItem">
                    <p>
                        Voor de stage begon moesten we een stageplan opstellen waarin we duidelijk maakte wat we gingen doen en hoe we het gingen
                        bewijzen. In het onderstaande stageplan vind je de vooraf gedeclareerde leerdoelen en competenties
                    </p>
                    <h2><a className="contains-a-blue" href={stageplan1} target="blanc">Stageplan versie 1</a></h2>
                    <h2><a className="contains-a-blue" href={stageplan3} target="blanc">Stageplan versie 2</a></h2>
                </div>
                <div className="internFoto">
                    <img src={teqplay} alt="foto" className="circle" />
                </div>
            </div>
        );
    }
}