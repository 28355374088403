import React from 'react'
import ImageZoom from 'react-medium-image-zoom'
import './proof.css'

import loadPoly from '../../../../../../assets/img/proof/analyse/loadPoly.png'
import settings from '../../../../../../assets/img/proof/analyse/settings.png'
import polyInfo from '../../../../../../assets/img/proof/analyse/polyInfo.png'
import filter from '../../../../../../assets/img/proof/analyse/filter.png'
import changeGraph from '../../../../../../assets/img/proof/analyse/changeGraph.png'
import drawPoly from '../../../../../../assets/img/proof/analyse/drawPoly.png'
import drag from '../../../../../../assets/img/proof/analyse/drag.png'
import saveLine from '../../../../../../assets/img/proof/analyse/saveLine.png'

import navMarker from '../../../../../../assets/img/proof/analyse/navMarker.png'
import navDrag from '../../../../../../assets/img/proof/analyse/navDrag.png'
import navInfo from '../../../../../../assets/img/proof/analyse/navInfo.png'
import navDraw from '../../../../../../assets/img/proof/analyse/navDraw.png'

const fotoWidth = '25em'

const beschrijving = <h5>Beschrijving</h5>
const userStory = <h5>User story</h5>

export const RequirementAnalyse =
    <div>
        <h3>Requirement analyse</h3>
        <p>
            De product owner gaf tijdens een demo aan dat hij de visualizer, (een applicatie door Maurice gebouwd die onze grafiek tekende en informatie daarvan 
            duidelijk liet zien met informatie over de punten, lijnen, wat met elkaar verbonden zit enzovoort) graag zou willen zien in een web-applicatie.
            Door deze vraag van de product owner ben ik overgestapt op de frontend. Hiervoor werden een aantal requirements opgesteld. 
            Deze requirements zullen in dit document geanalyseerd worden.
        </p>

        <p>
            In de requirement analyse zal ik de requirements van de applicatie benoemen, wat het moet gaan doen en een foto van een kaart op het Trello bord.
        </p>

        <h4>Requirements:</h4>
        <div className="contains-a">
            <ul>
                <li>
                    <a href="#algemeen">Algemenen requirements:</a>

                    <ul>
                        <li><a href="#algemeen-visueel">Inzien van het routenetwerk/grafiek door polylines te tekenen op een map.</a></li>
                        <li><a href="#algemeen-settings">Aanpassen van settings.</a></li>
                    </ul>
                </li>

                <li>
                    <a href="#info-tool">Info tool:</a>
                    <ul>
                        <li><a href="#info-click" >Polylines aanklikken om info in te zien.</a></li>
                        <li><a href="#info-filteren">Filteren op polyline sources.</a></li>
                        <li><a href="#info-aanpassen">Informatie aanpassen van de grafiek.</a></li>
                    </ul>
                </li>

                <li>
                    <a href="#draw-tool">Draw tool:</a>
                    <ul>
                        <li><a href="#draw-tekenen">Tekenen van punten.</a></li>
                        <li><a href="#draw-verschuiven">Verschuiven van punten.</a></li>
                        <li><a href="#draw-save">Opslaan van getekende lijnen.</a></li>
                    </ul>
                </li>

                <li>
                    <a href="#nav-tool">Navigation tool:</a>
                    <ul>
                        <li><a href="#nav-punten">Start, via en eindpunten op de map zetten. (markers)</a></li>
                        <li><a href="#nav-verschuiven">De markers verplaatsen door ze te verslepen.</a></li>
                        <li><a href="#nav-info">Informatie betreft CEMT klasse mee geven.</a></li>
                        <li><a href="#nav-tekenen">Route(s) tekenen over de grafiek.</a></li>
                    </ul>
                </li>
            </ul>
        </div>

        <div id="algemeen" className="section">
            <h3>Algemeen</h3>

            <h4 id="algemeen-visueel">Visueel maken van Polylines</h4>
            
            {userStory}
            <p>
                Als een gebruiker wil ik de routeplanner grafiek kunnen inzien met lijnen (polylines), omdat ik met deze polylines informatie kan krijgen hoe de 
                grafiek er uit ziet en waar er mogelijke problemen zitten. 
            </p>

            {beschrijving}
            <p>
                Bij deze requirement komen wat dingen bij kijken, zoals het binnen halen van de data die door de backend wordt opgesteld.
                Na het binnenhalen moet de data worden verwerkt zodat het in de frontend kan worden gebruikt.
                Als die twee stappen voltooid zijn moet de data worden geprint op een map. Voor de map gebruik ik een library genaad Leaflet.
                Leaflet is een open source library die hier goed mee werkt.
            </p>

            <p>Hier onder zie je een foto van een kaart op het Trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: loadPoly,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="algemeen-settings">Aanpassen van settings.</h4>
            
            {userStory}
            <p>
                Als gebruiker wil aanpassingen kunnen doen aan settings, zodat ik kan filteren wat ik wil zien en hoe ik het wil zien.
            </p>

            {beschrijving}
            <p>
                Bij deze requirement is het de bedoeling de de gebruiker settings als in map kleur kan aanpassen.
                Hier zou ook kunnen worden aangegeven wat de gebruiker wil zien, zoals bruggen of sluizen.
                De gebruiker kan hier ook beslissen om bijvoorbeeld geen polylines te willen zien.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: settings,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

        </div>

        <div id="info-tool" className="section">
            <h3>Info tool</h3>
            <h4 id="info-click">Polylines aanklikken om info in te zien.</h4>

            {userStory}
            <p>
                Als gebruiker wil ik lijnen (polylines) kunnen aanklikken, zodat ik dan over de geselecteerde polyline informatie kan inzien.
            </p>
            
            {beschrijving}
            <p>
                Als je op een polyline klikt moet er een popup moet verschijnen.
                Deze popup moet gevuld worden met de informatie die bij de polyline hoort. Deze taken vormen samen deze requirement.
                Dankzij deze requirement kunnen we goed zien welke lijnen welke informatie bevatten, zodat we hiermee problemen kunnen achterhalen en kunnen oplossen.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: polyInfo,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="info-filteren">Filteren op polyline sources.</h4>

            {userStory}
            <p>
                Als gebruiker wil kunnen filteren op polyline sources, zodat ik duidelijk kan inzien welke source welke routes bevatten.
            </p>

            {beschrijving}
            <p>
                Uit de backend krijgen we een lijst aan polylines. Deze bevatten ook informatie waar ze vandaan komen.
                Deze requirement zorgt ervoor dat er gefilterd kan worden op alle sources die in onze database staan.
                Naast het uitzetten van verschillende sources is er ook de mogelijkheid om de sources een eigen kleur te geven om zo goed het verschil te kunnen zien.
            </p>
            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: filter,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="info-aanpassen">Informatie aanpassen van de grafiek.</h4>
            {userStory}
            <p>
                Als gebruiker wil ik gegevens uit de grafiek kunnen aanpassen, omdat ik hierdoor fouten uit de gebruikte data kan verbeteren waardoor de grafiek 
                steeds beter word.
            </p>

            {beschrijving}
            <p>
                Deze Requirement is bedoeld om de sources te gaan verbeteren. Er zijn enkele fouten in de data die we gebruiken.
                Deze fouten zouden dan worden opgelost met deze requirement, zodat de grafiek zich telkens kan blijven verbeteren.
            </p>
            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: changeGraph,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
        </div>

        <div id="draw-tool" className="section">
            <h3>Draw tool</h3>
            <h4 id="draw-tekenen">Tekenen van punten.</h4>
            {userStory}
            <p>
                Als gebruiker wil ik punten/markers kunnen tekenen op de map die samen een lijn (polyline) vormen, zodat hierdoor de grafiek verbeterd kan worden.
            </p>

            {beschrijving}
            <p>
                Met het tekenen van de punten kunnen we nieuwe lijnen tekenen. Deze nieuwe lijnen kunnen gebruikt worden in het berekenen van de routes.
                Het belangrijkste van dit is dat de punten duidelijk worden aangegeven op de map en dat je de lijn kan zien tussen de twee punten.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: drawPoly,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="draw-verschuiven">Verschuiven van punten.</h4>
            
            {userStory}
            <p>
                Als gebruiker wil ik de getekende punten kunnen verschuiven, zodat ik hierdoor de getekende lijn kan verbeteren en veranderen.
            </p>

            {beschrijving}
            <p>
                Om de lijnen zo goed mogelijk te kunnen tekenen is het erg handig om deze te kunnen verslepen, zodat de getekende lijn 
                hierdoor makkelijk verbeterd kan worden. Tussen 2 getekende punten komt ook een middelpunt te staan. Het middelpunt wordt ook versleepbaar.
                Dit zorgt ervoor dat je de getekende lijn kan blijven verbeteren en meer detail in kan brengen als dat nodig is.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: drag,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="draw-save">Opslaan van getekende lijnen.</h4>
            {userStory}
            <p>
                Als gebruiker wil ik de getekende lijnen kunnen opslaan, zodat deze gebruikt kunnen worden opgeslagen in de database en in de routeplanner applicatie 
                kunnen worden gebruikt.
            </p>

            {beschrijving}
            <p>
                Als de lijn getekend is moet deze worden opgeslagen in de database. Met het sturen naar de backend worden alle punten
                en alle informatie die daar bijhoren ingevuld en opgestuurd naar de backend.
                Daarna moet de lijn actief worden gezet en kan gebruikt worden in het navigeren.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: saveLine,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
        </div>

        <div id="nav-tool" className="section">
            <h3>Navigation tool</h3>
            <h4 id="nav-punten">Start, via en eindpunten op de map zetten. (markers)</h4>
            {userStory}
            <p>
                Als gebruiker wil ik kunnen aangeven van waar ik mijn begin, eind en via-punt(en) wil plaatsen, zodat ik met deze locaties 
                een route kan plannen/testen.
            </p>

            {beschrijving}
            <p>
                Dit is de belangrijkste requirement van de navigatie tool. Deze requirement zorgt er voor dat je een start en eindpunt en als je het wil via punten.
                De punten worden aangegeven met markers. De markers zullen zelfde design hebben als de markers in de teken tool, maar dan met een andere kleur (blauw).
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: navMarker,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
            <h4 id="nav-verschuiven">De markers verplaatsen door ze te verslepen.</h4>

            {userStory}
            <p>
                Als gebruiker wil ik de navigatie markers kunnen verplaatsen zodat ik de route kan aanpassen en of verbeteren.
            </p>
            
            {beschrijving}
            <p>
                Dit een requirement die ook voorkomt in de teken tool. 
                De markers in de navigatie tool zullen ook versleepbaar zijn zodat hier met meer gemak mee gewerkt kan worden.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: navDrag,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
            <h4 id="nav-info">Informatie betreft CEMT klasse mee geven.</h4>

            {userStory}
            <p>
                Als gebruiker wil in informatie kunnen mee geven aan de routeplanner, zodat ik de routes kan zien die aan de juiste informatie voldoen.
            </p>

            {beschrijving}
            <p>
                Met het opvragen van de route kan ook informatie mee gegeven worden aan de backend. 
                Informatie zoals schip klasse mee geven helpt om een route te zoeken waar het schip overheen kan.  
                Deze informatie kan worden mee gegeven om te testen of de getekende lijn werkt en of de meegegeven CEMT klasse ook werkt.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: navInfo,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />

            <h4 id="nav-tekenen">Route(s) tekenen over de grafiek.</h4>
            {userStory}
            <p>
                Als gebruiker wil ik de opgevraagde routes kunnen zien op de map, zodat ik kan zien welke routes er zijn om van punt A naar B te gaan.
            </p>

            {beschrijving}
            <p>
                Als het start en eindpunt is aangegeven en de nodige informatie is ingevuld dan worden deze naar de backend gestuurd. 
                De backend stuurt hier weer polylines voor terug. De polylines zijn alle mogelijke routes van A naar B.
                Deze routes zullen over de polylines worden getekend.
            </p>

            <p>Hier onder zie je een foto van een kaart op het trello bord die te maken heeft met deze requirement.</p>
            <ImageZoom
                image={{
                    src: navDraw,
                    alt: 'Foto',
                    style: { width: fotoWidth }
                }}
            />
        </div>

    </div>