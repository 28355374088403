import React from 'react'
import ImageZoom from 'react-medium-image-zoom'
import './proof.css'

import componentDiagramFoto from '../../../../../../assets/img/proof/components.png'
import colorPalette from '../../../../../../assets/img/proof/colorPalette.png'
import colorPalleteValidatie from '../../../../../../assets/img/proof/colorPalleteValidatie.png'

import wireframe1 from '../../../../../../assets/img/proof/wireframe1.jpg'
import wireframe2 from '../../../../../../assets/img/proof/wireframe2.jpg'
import wireframe3 from '../../../../../../assets/img/proof/wireframe3.jpg'
import wireframe3Validatie from '../../../../../../assets/img/proof/wireframe3Validatie.png'

const fotoWidth = '50em'

export const componentDiagram =
    <div>
        <h4>Component diagram</h4>
        <p>
            In dit diagram laat ik zien hoe ik het systeem ga opbouwen. Tijdens het bespreken van de frontend applicatie kwamen er een aantal feature naar voren die
            het moest gaan bevatten. Deze features waren een informatie tool, een teken tool en een onderhoud tool. Met deze features zal de frontend onze backend
            gaan ondersteunen.
        </p>

        <h4>Info tool</h4>
        <p>

            Deze tool zal gaan functioneren om de grafiek te kunnen bekijken en door op een polyline te klikken je de informatie van de desbetreffende route te kunnen zien.
        </p>

        <h4>Teken tool</h4>
        <p>
            Deze tool zal gaan functioneren om handmatig routes toe te gaan kunnen voegen aan de grafiek. Dit zal gaan werkend door op de map te klikken zodat
            er nieuwe markers verschijnen en dat die markers aan elkaar gebonden worden als nieuwe polyline. Mocht je een nieuwe lijn getekend hebben kan je deze
            opslaan zodat hij daarna in gebruik wordt genomen door de grafiek.
        </p>

        <h4>Onderhoud tool</h4>
        <p>
            In de onderhoud tool krijg je de mogelijkheid om de getekende lijnen aan te passen of te verwijderen.
        </p>
        <ImageZoom
            image={{
                src: componentDiagramFoto,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />

        <h4>Validatie</h4>
        <p>
            In het component diagram zie je alle verschillende aspecten die in de applicatie komen.
            Ik zal per component een referentie maken naar de bij behoorende requirement.
            Het diagram geeft weer door middel van pijlen wat met elkaar verbonden is.
        </p>

        <p>
            Je hebt bijvoorbeeld de LeafletMap. Hierin zitten verschillende requirements zoals de Info popup.
            Je kan zien dat er een pijl vanuit de info tool naar de info popup toegaat dit betekend dat de info popup zich alleen bevind in deze requirement.
        </p>
        <h5>Algemeen</h5>
        <p>
            Bij de algemenen requirements horen de volgende.
        </p>
        <div>1 - Inzien van het routenetwerk/grafiek door polylines te tekenen op een map.</div>
        <div>2 - Aanpassen van settings.</div>
        <div>Requirement 1 hoort bij het 'Load/view all polylines' component. </div>
        <div>Requirement 2 hoort bij het 'settings' component. </div>

        <h5>Info tool</h5>
        <p>
            Bij de info tool komen de volgende requirements in voor.
        </p>
        <div> 1 - Polylines aanklikken om info in te zien.</div>
        <div> 2 - Filteren op polyline sources.</div>
        <div> 3 - Informatie aanpassen van de grafiek.</div>
        <div>Requirement 1 hoort bij het 'Clickable polylines' en 'Info popup' component. </div>
        <div>Requirement 2 hoort bij het 'Filter sources' component.</div>
        <div>Requirement 3 hoort bij het 'Change Info' component.</div>

        <h5>Teken tool</h5>
        <p>
            Bij de teken tool komen de volgende requirements voor.
        </p>
        <div> 1 - Tekenen van punten.</div>
        <div> 2 - Verschuiven van punten.</div>
        <div> 3 - Opslaan van getekende lijnen.</div>
        <div>Requirement 1 hoort bij het 'Draw markers' component. </div>
        <div>Requirement 2 hoort bij het 'Drag markers' component.</div>
        <div>Requirement 3 hoort bij het 'Save new polyline' component.</div>

        <h5>Navigatie tool</h5>
        <p>
            Bij de navigatie tool komen de volgende requirements voor.
        </p>
        <div> 1 - Start, via en eindpunten op de map zetten. (markers)</div>
        <div> 2 - De markers verplaatsen door ze te verslepen.</div>
        <div> 3 - Informatie betreft CEMT klasse mee geven.</div>
        <div> 4 - Route(s) tekenen over de grafiek.</div>
        <div>Requirement 1 hoort bij het 'Location input' component. </div>
        <div>Requirement 2 hoort bij het 'Drag markers' component.</div>
        <div>Requirement 3 hoort bij het 'Information input form' component.</div>
        <div>Requirement 4 hoort bij het 'Draw routes on graph' component.</div>

        <h4>Gekozen kleuren palette</h4>
        <p>
            Voor dit project ben ik opzoek gegaan naar een color palette om te gebruiken in het design, na wat zoeken heb ik gekozen
            voor deze palette omdat de kleuren goed bij de map pasten.
        </p>

        <ImageZoom
            image={{
                src: colorPalette,
                alt: 'Foto',
                style: { width: '25em' }
            }}
        />
        &nbsp;
        <ImageZoom
            image={{
                src: colorPalleteValidatie,
                alt: 'Foto',
                style: { width: '32.75em' }
            }}
        />
        <h4>Validatie</h4>
        <p>
            In de validatie zal er worden verwezen naar de foto van het kleuren palet en de daarnaast staande foto van een screen shot de applicatie.
            De kleuren zijn genummerd en in het screenshot staan namen waarnaar verwezen wordt.
        </p>
        <p>
            Van het kleuren palet heb ik 3 kleuren gebruikt. Dit zijn de kleuren 3, 4 en 5
            Kleur 3 komt terug in de settings knop, in de settings en modus bar knoppen.
            Deze kleur vindt ik hier goed bij passen en gaat goed samen met de donkere kleur van het palet.
            Kleur 4 gebruik ik in de popups. Deze kleur lijkt in het voorbeeld iets lichter als in het palet.
            Dat komt omdat hij een beetje transparant is.
            Kleur 5 gebruik ik voor buttons, header, in de settings en in de modus bar.
            Deze kleur vind ik het mooist om hiervoor te gebruiken omdat hij duidelijk te zien is, maar toch rustig om naar te kijken.
        </p>

        <h4>Wireframes</h4>
        <p>
            Voor ik ging werken aan de frontend heb ik designs gemaakt en deze besproken met andere frontenders (Babette en Bas).
            Na de feedback heb ik ze veranderd naar het uiteindelijk design.
        </p>

        <h4>Wireframe 1: Eerste design</h4>
        <p>
            Dit was het eerste design van de frontend hierover heb ik feedback ontvangen van Babette en Bas.
            Hier uit werd duidelijk wat ik nog kon verbeteren voor het uiteindelijke design.
        </p>

        <ImageZoom
            image={{
                src: wireframe1,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />

        <h4>Validatie</h4>
        <p>
            Bij het eerste design zie je een aantal requirements terug komen. Je ziet een informatie popup dat verwijst daar de requirement.
            De requirements die hier in voor komen zijn:
        </p>
        <div> - Polylines aanklikken om info in te zien.</div>
        <div> - Aanpassen van settings.</div>

        <h4>Wireframe 2: Tweede design</h4>
        <p>
            Dit wordt het uiteindelijke design van de frontend hierin is alle feedback van Babette en Bas verwerkt tot het uiteindelijke resultaat.
        </p>

        <ImageZoom
            image={{
                src: wireframe2,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />

        <h4>Validatie</h4>
        <p>
            Bij deze wireframe komen de zelfde requirements terug als in het eerste wireframe.
            De requirements die hier in voor komen zijn:
        </p>
        <div> - Polylines aanklikken om info in te zien.</div>
        <div> - Aanpassen van settings.</div>

        <h4>Wireframe 3: Modus bar</h4>
        <p>
            Dit is het design voor de uiteindelijke modus bar. De bedoeling is om hier te kunnen switchen van modus bijvoorbeeld info naar teken-tool.
            Als je over de geselecteerde mode modus beweegt zullen de functionaliteiten uitschuiven (soort drop down menu).
        </p>
        <p>
            Na het overleggen met Catalina en Maurice heb ik na hun inzicht gekozen voor de bovenste optie.
        </p>

        <ImageZoom
            image={{
                src: wireframe3,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />

        <h4>Validatie</h4>
        <p>
            Bij deze wireframe horen geen echte requirements, maar is van het switchen tussen de verschillende modes.
            Om deze te valideren heb ik een screen shot met het uit design uitgewerkt naar het resultaat.
        </p>
        <ImageZoom
            image={{
                src: wireframe3Validatie,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />
    </div>