import React, {Component} from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { componentDiagram } from './proof/ComponentDiagram'

import visualiseren from '../../../../../assets/img/proof/feedback/visualiseren.png'
import bespreken from '../../../../../assets/img/proof/feedback/bespreken.png'
import afspraken from '../../../../../assets/img/proof/feedback/afspraken2.png'
import { Libraries } from './proof/Libraries';


export default class Ontwerpen extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie Ontwerpen 1</h2>

                <h3>Info over de competentie</h3>
                <p>
                    Je kunt een ontwerp opstellen voor een
                    (deel van een) softwaresysteem en je maakt
                    hierbij gebruik van bestaande componenten
                    en libraries
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    UML modellen om het systeem in kaart te
                    brengen en hoe verschillende componenten
                    met elkaar samenwerken.
                </p>

                <h2>Competentie Ontwerpen 2</h2>

                <h3>Info over de competentie</h3>
                <p>
                    Je kunt een validatie voor je ontwerp
                    uitvoeren op basis van specificaties uit de
                    (eerder gemaakte) analyse
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Het ontwerp valideren met de opgestelde
                    requirements en deze verwerken in een
                    verslag.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: visualiseren,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <ImageZoom
                    image={{
                        src: bespreken,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <ImageZoom
                    image={{
                        src: afspraken,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Vooral aan het begin van het opzetten van de applicatie liet ik veel schetsen zien hoe de applicatie in elkaar zat.
                    Dit gaf ons maar ook de product owner en begeleiders een goed overzicht wat we hadden opgezet.
                    Deze schetsen hielpen ook met het verbeteren van het product en hebben ook veel geholpen aan het uit eindelijke resultaat.
                </p>

                <p>
                    In de frontend heb ik veel schetsen gemaakt hoe de applicatie gevisualiseerd worden. Deze schetsen zijn goed vertaald naar de applicatie.
                    Ik ben hier erg tevreden mee dat dat gelukt is. Je kan goed de gelijkenisse zien tussen de schetsen en het uiteindelijke resultaat.
                </p>

                <p>
                    Afspraken over de applicatie en hoe deze er een beetje uit moest komen te zien werden vaak tijdens de demo's besproken.
                    Deze afspraken werden dan ook nageleefd.
                </p>


                <h2>Bewijs</h2>
                {componentDiagram}

                {Libraries}
            </div>
        );
    }
}
