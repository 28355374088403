import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import trello from '../../../../../assets/img/proof/beheren/trello.png'
import afspraken1 from '../../../../../assets/img/proof/feedback/afspraken1.png'
import afspraken2 from '../../../../../assets/img/proof/feedback/afspraken2.png'
import dagboek from '../docs/teqplay/Dagboek.pdf'
import './proof/proof.css'

export default class Beheer extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie beheren 1</h2>

                <h3>Info over de competentie</h3>
                <p>
                    Je kunt werken volgens een vooraf
                    vastgesteld en goedgekeurd stageplan
                    (inclusief planning) en eventuele afwijkingen
                    onderbouwen.
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Ik hou elke dag bij wat ik gedaan heb op het
                    Trello bord en in mijn dagboek wat ik schrijf in
                    google docs. Dit document en scrumbord
                    zullen laten zien dat ik kan werken volgens
                    mijn vooropgestelde stageplan en zullen
                    mogelijke afwijkingen ook onderbouwen.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: afspraken1,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <ImageZoom
                    image={{
                        src: afspraken2,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    De originele planning was om in de stageperiode een backend op te zetten die als routeplanner zou functioneren.
                    Het originele plan was om in het project alleen een backend te gebruiken maar dit veranderde na een aantal sprints.
                </p>
                <p>
                    De uiteindelijke planning verschilt wat van de originele planning. Er werd na een aantal sprints gevraagd om een
                    frontend applicatie die ondersteuning zou geven aan de backend. Deze verandering in planning gaf mij de mogelijkheid
                    om meer te leren tijdens mijn stage. Door deze verandering heb ik het eerste gedeelte kunnen leren programmeren in Kotlin en
                    in het tweede gedeelte heb ik geleerd hoe het is om met frontend te werken.
                </p>
                <p>
                    Als ik terug kijk op de originele planning ben ik blij dat hier van afgeweken is.
                    Ik heb hierdoor ervaren dat frontend toch wel wat voor mij is en doe dit ook erg graag.
                    Ik heb hierdoor meer geleerd dan wat ik vooraf had gezegd dat ik wilde leren.
                </p>

                <h2>Bewijs</h2>
                <p><a className="contains-a-blue" href={dagboek} target="blanc" >Open dagboek</a></p>
                <ImageZoom
                    image={{
                        src: trello,
                        alt: 'Foto',
                        style: { width: '50em' }
                    }}
                />
            </div>
        );
    }
}