import React, { Component } from 'react'

import { bedrijfsoriëntatie } from './proof/BedrijfsOrientatie'

export default class Skills1 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Competentie Skills 1</h2>

                <h3>Info over de competentie</h3>
                <p>
                    Je kunt aangeven welke
                    bedrijfskenmerken je terugziet in je
                    stagebedrijf.
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Bedrijfsoriëntatie
                </p>

                <h2>Bewijs</h2>
                {bedrijfsoriëntatie}
            </div>
        );
    }
}