import React from 'react';
import Tesla from './items/internships/Tesla';
import Parcsapp from './items/internships/Parcsapp';
import Teqplay from './items/internships/Teqplay/Teqplay';
import notFound from './items/nothing';
import CodersAcademy from './items/internships/CodersAcademy';


const Internships = ({ match }) => {
    var name = match.params.name
    var t;
        switch(name){
            case("tesla"):
                t = <Tesla />
                break;
            case("coders"):
                t = <CodersAcademy />
                break;
            case("parcsapp"):
                t = <Parcsapp />
                break;
            case("teqplay"):
                t = <Teqplay />
                break;
            default:
                t = notFound
                break;
        }
        return (<div>{t}</div>);
}



export default Internships;