import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

import kotlin1 from '../../../../../../assets/img/proof/leerdoelen/kotlin1.png'
import kotlin2 from '../../../../../../assets/img/proof/leerdoelen/kotlin2.png'
import kotlin3 from '../../../../../../assets/img/proof/leerdoelen/kotlin3.png'

const fotoWidth = '25em'

export const Kotlin =
    <div>
        <h3>Kotlin leerdoel</h3>
        <p>
            Dit leerdoel heb ik tijdens mijn eerste weken behaald toen ik nog in het backend project werkte.
        </p>
        <p>
            Tijdens het werken aan de backend heb ik veel gewerkt om de data op te stellen en deze te verwerken. 
            Hier voor heb ik veel logica geschreven die in de onderstaande foto te zien is.
            Dit soort logica was om verschillende data te verwerken tot data wat we konden en gingen gebruiken in de applicatie.
        </p>
        <div>
        <ImageZoom
            image={{
                src: kotlin1,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />
        </div>

        <p>
            Naast logica hield ik me ook veel bezig om data naar buiten open te stellen zodat onze applicatie gebruikt kon gaan worden. 
            Dit openstellen werd gedaan met Controllers die werkte met het Springboot framework. Door de Controllers werd de de data opengesteld via een api link.
            (bijvoorbeeld: www.example.com/getData/)
            Zo'n link geeft dan de data terug die verwacht wordt door de gebruiker.
        </p>
        <p>
            Hier onder zie je op de foto's een Controller function: (links eerste gedeelte, rechts tweede gedeelte)
        </p>

        <ImageZoom
            image={{
                src: kotlin2,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />
        &nbsp;
        <ImageZoom
            image={{
                src: kotlin3,
                alt: 'Foto',
                style: { width: fotoWidth }
            }}
        />
    </div>