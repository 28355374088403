import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import feedback3 from '../../../../../assets/img/proof/feedback/kwaliteitseigenschappen.png'

import './proof/proof.css'

export default class Leerdoel1 extends Component {
    render() {
        return (
            <div className="competentie">
                <h2>Leerdoel 3</h2>
                <h3>Over het leerdoel</h3>
                <p>
                    Tijdens mijn stage ben ik gewisseld van backend naar frontend en hier wilde ik een leerdoel voor opstellen.
                    Het leerdoel zal zijn om een project te kunnen maken in ReactJS met JavaScript.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: feedback3,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>https://reactjs.org/docs/react-component.html
                <p>https://reactjs.org/docs/react-component.html
                    Toen ik gewisseldhttps://reactjs.org/docs/react-component.html was naar de frontend was dit om te kijken hoe het was.
                    Dit pakte voor mihttps://reactjs.org/docs/react-component.htmlj echter verrassend uit. Ik pakte het programmeren in ReactJS met JavaScript goed op
                    dankzij een goede begeleiding van Babette en Bas.
                </p>
                <p>
                    Ik heb van mijn RiverScout project veel positieve reacties gekregen tijdens demo en ben ook erg tevreden wat ik heb kunnen opzetten in ongeveer 7 weken.
                    Het project is nog niet helemaal af maar wordt al wel gebruikt voor het testen en bijhouden van de backend applicatie van RiverScout.
                </p>
                <p>
                    Ik heb veel geleerd van het RiverScout project en weet nu goed hoe ik een project moet opzetten en waar ik op moet letten om het project zo net n
                    mogelijk te maken en houden.
                </p>

                <h2>Persoonlijke groei</h2>
                <p>
                    Toen ik overstapte naar frontend was dat met het idee om te kijken hoe het is om als frontender te werken. 
                    Ik had geen tot weinig kennis of ReactJS en leerde dus alles door het uit te zoeken en als het niet lukte te vragen aan andere collega's.
                    Ik heb met het RiverScout project veel geleerd en weet nu hoe ik aan een project met ReactJS moet werken. 
                    De eerste weken had ik vaak nog wel hulp nodig, maar dit werd snel al minder tot op het punt dat ik genoeg kennis had opgebouwd 
                    om alles zelf te kunnen uitvoeren en oplossen.
                </p>
                <p>
                    Tijdens het leren van ReactJS heb ik ook veel informatie van het internet gehaald. Hiervoor ik heb websites gebruikt.
                    Hier onder vind je een paar voorbeelden waar ik informatie vandaan heb.
                </p>

                <ul>
                    <li>
                        <a className="contains-a-blue" href="https://reactjs.org/docs/getting-started.html" target="blanc">ReactJS documentatie </a>
                        voor basis kennis.
                    </li>
                    <li>
                        <a className="contains-a-blue" href="https://stackoverflow.com/" target="blanc">Stack overflow </a>
                        voor problemen oplossen.
                    </li>
                    <li>
                        <a className="contains-a-blue" href="https://reactjs.org/tutorial/tutorial.html" target="blanc">ReactJS tutorial </a>
                        om te beginnen met ReactJS.
                    </li>
                </ul>

                <h2>Bewijs</h2>
                <p>
                    Als bewijs voor het leren van ReactJS verwijs ik naar de Realiseren 1 competentie. Hierin staan alle requirements voor de 
                    frontend uitgewerkt met een foto als bewijs.
                </p>
                <a href="/internships/teqplay/realiseren1#bewijs" className="link">
                    Link naar realiseren 1 competentie waarin de frontend gedemonstreerd wordt.
                </a>
            </div>
        );
    }
}