import React, { Component } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { AdviesRapport } from './proof/AdviesRapport';

import advies1 from '../../../../../assets/img/proof/feedback/advies.png'

export default class advies extends Component {
    render() {
        console.log("test")
        return (
            <div className="competentie">
                <h2>Competentie Advieseren 1</h2>
                <h3>Info over de competentie</h3>
                <p>
                    Je kunt een praktijkprobleem ontleden
                    en een aanbeveling hiervoor doen. Let er
                    hierbij op dat je helder weergeeft wat de
                    probleembeschrijving is, wat de onderbouwde
                    argumenten zijn en wat de keuze is. Maak
                    gebruik van betrouwbare informatiebronnen
                    (bijvoorbeeld o.a. veldonderzoek en
                    deskresearch)
                </p>

                <h3>Bewijs volgens stageplan</h3>
                <p>
                    Een praktijkprobleem van Riverscout, zoals
                    keuze in route-algoritme library. De keuzes
                    zullen worden uitgewerkt in het adviesrapport
                    en zal voor elk een stuk worden beschreven
                    wat de gevolgen zijn van de keuze.
                    Uiteindelijk wordt in de conclusie gezet welke
                    van de keuzes het is geworden.
                </p>

                <h2>Reflectie & feedback</h2>

                <h3>Feedback</h3>
                <ImageZoom
                    image={{
                        src: advies1,
                        alt: 'Foto',
                        style: { width: '25em' }
                    }}
                />

                <h3>Reflectie</h3>
                <p>
                    Het begin van het project was veel tools analyseren zodat we een advies konden gaan geven.
                    Dit onderzoek verliep goed en heb de tools getest en gebruikt of geprobeerd te gebruiken.
                    Na het onderzoek op de verschillende tools hebben we een goed advies kunnen geven en hebben we dankzij deze tool een mooie applicatie kunnen bouwen.
                </p>

                <h2>Bewijs</h2>
                {AdviesRapport}
            </div>
        );
    }
}
